import React, { FC, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import ColoredTile from 'components/ColoredTile';
import MobileGuttersContainer from 'components/MobileGuttersContainer';
import Section from 'components/Section';
import TextButton from 'components/TextButton/TextButton';
import manage_dashboard_messages from 'messages/manage_dasboard_messages';
import { useHelpDialogContext } from 'reactContext/HelpDialogContext/HelpDialogContext';
import { Id } from 'types/Id';

import useGetManagementReportBaseData from '../../_hooks/useGetManagementReportBaseData';
import ManagementCommentInput from '../ManagementCommentInput/ManagementCommentInput';
import ManagementDashboardKPIGrid from '../ManagementDashboardKPIGrid/ManagementDashboardKPIGrid';
import ManagementDashboardSectionGrid from '../ManagementDashboardSectionGrid/ManagementDashboardSectionGrid';

type Props = {
  reportId?: Id;
};

const ManagementDashboardIncidentsSection: FC<Props> = ({ reportId }) => {
  const { t } = useTranslation();
  const { showHelpDialog } = useHelpDialogContext();

  const showIntroductionDialog = () => {
    showHelpDialog({
      title: t(manage_dashboard_messages.incidents_section.introduction_title),
      body: t(manage_dashboard_messages.incidents_section.introduction_body),
    });
  };

  const { data } = useGetManagementReportBaseData(reportId);

  const kpiData = useMemo(() => {
    const { majorIncidentsReport } = data || {};
    return [
      {
        description: t(manage_dashboard_messages.incidents_section.total_incidents_kpi_label),
        title: majorIncidentsReport?.totalIncidents ?? null,
      },
      {
        description: t(manage_dashboard_messages.incidents_section.gdpr_incidents_kpi_label),
        title: majorIncidentsReport?.gdprIncidents ?? null,
      },
      {
        description: t(manage_dashboard_messages.incidents_section.ongoing_incidents_kpi_label),
        title: majorIncidentsReport?.ongoingIncidents ?? null,
      },
      {
        description: t(manage_dashboard_messages.incidents_section.highest_incidents_kpi_label),
        title: majorIncidentsReport?.highestIncidents ?? null,
      },
      {
        description: t(manage_dashboard_messages.incidents_section.high_incidents_kpi_label),
        title: majorIncidentsReport?.highIncidents ?? null,
      },
      {
        description: t(manage_dashboard_messages.incidents_section.medium_incidents_kpi_label),
        title: majorIncidentsReport?.mediumIncidents ?? null,
      },
      {
        description: t(manage_dashboard_messages.incidents_section.low_incidents_kpi_label),
        title: majorIncidentsReport?.lowIncidents ?? null,
      },
    ];
  }, [data, t]);

  return (
    <Section
      title={manage_dashboard_messages.incidents_section.title}
      titleLineAddon={[
        <TextButton key='introduction' onClick={showIntroductionDialog}>
          {t(manage_dashboard_messages.incidents_section.introduction_button)}
        </TextButton>,
      ]}
      // @ts-ignore
      titleWrapper={MobileGuttersContainer}
    >
      <ManagementDashboardSectionGrid>
        <MobileGuttersContainer>
          <ManagementDashboardKPIGrid>
            {kpiData.map(({ description, title }) => (
              <ColoredTile key={description} centered description={description} disableRipple isLoading={title === null} title={title} />
            ))}
          </ManagementDashboardKPIGrid>
          <ManagementCommentInput
            fieldKey='majorIncidentsReport'
            initialValue={data?.majorIncidentsReport.comment || ''}
            reportId={reportId}
          />
        </MobileGuttersContainer>
      </ManagementDashboardSectionGrid>
    </Section>
  );
};

export default ManagementDashboardIncidentsSection;

const menu_messages = {
  about: ['menu>>about', 'About Pocketsafe'],
  pricing: ['menu>>pricing', 'Pricing'],
  it_security: ['menu>>it_security', 'IT Security'],
  dashboard: ['menu>>dashboard', 'My Dashboard'],
  login: ['menu>>login', 'Login'],
  logout: ['menu>>logout', 'Log out'],
  profile: ['menu>>profile', 'My profile'],
  incident_management: ['menu>>incident_management', 'Incident management'],
  coworkers: ['menu>>coworkers', 'Our coworkers'],
  sign_up: ['menu>>sign_up', 'Sign up'],
  articles: ['menu>>articles', 'Articles'],
  guides: ['menu>>guides', 'Guides'],
  self_tests: ['menu>>self_tests', 'Self Tests'],
  manage_content: ['menu>>manage', 'Manage content'],
  content: ['menu>>content', 'Content'],
  service: ['menu>>service', 'Service area'],
  organization: ['menu>>organization', 'My organization'],
  book_meeting: ['menu>>book_meeting', 'Book an advice meeting'],
  incident_reports: ['menu>>incident_reports', 'Incident management'],
  meeting_time_slots: ['menu>>meeting_time_slots', 'My meeting time slots'],
  ps_administrator: ['menu>>ps_administrator', 'PocketSafe - administration'],
  advisory_page: ['menu>>advisory_page', 'Advisory page'],
  meeting_advices: ['menu>>meeting_advices', 'Advices'],
  organization_administration: ['menu>>organization_administration', 'Organization administration'],
  ask_question: ['menu>>ask_question', 'Ask a question'],
  questions: ['menu>>questions', 'Questions'],
  emergency_wrapper_button: ['menu>>emergency_wrapper_button', 'Emergency'],
  emergency_button: ['menu>>emergency_button', 'I need help with emergency'],
  report_incident: ['menu>>report_incident', 'Report incident'],
  workplaces: ['menu>>workplaces', 'Workplaces'],
  assets: ['menu>>assets', 'Assets'],
  dashboards: ['menu>>dashboards', 'Our Dashboards'],
  asset_task_dashboard: ['menu>>asset_task_dashboard', 'Asset Tasks'],
  risk_analysis_dashboard: ['menu>>risk_analysis_dashboard', 'Risk Analysis'],
  risk_analysis_tasks_dashboard: ['menu>>risk_analysis_tasks_dashboard', 'Risk Analysis Tasks'],
  management_dashboard: ['menu>>management_dashboard', 'Management'],
};

export default menu_messages;

import { RiskScaleValue } from 'config/api/assetRisks/assetRisks.types';
import { StaticTableFilter } from 'types/TableFilter';

const filters: { id: RiskScaleValue; name: string }[] = [
  { id: 1, name: '1' },
  { id: 2, name: '2' },
  { id: 3, name: '3' },
  { id: 4, name: '4' },
];

const riskQuadrantFilterFactory = (sectionName: string[]): StaticTableFilter => ({
  isDynamic: false,
  sectionName,
  filters,
  isSingleSelect: true,
});

export default riskQuadrantFilterFactory;

import React from 'react';

import { Button, CircularProgress, IconButton, makeStyles } from '@material-ui/core';
import { darken } from '@material-ui/core/styles/colorManipulator';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const colorResolver = (color, colorPalette) => {
  if (color === 'none') {
    return {
      icon: { base: 'transparent', hover: colorPalette.grey[100], disabled: 'transparent', opacity: 0.7 },
      button: { base: 'transparent', hover: colorPalette.grey[200], disabled: 'transparent' },
    };
  }
  if (color === 'danger') {
    return {
      icon: { base: colorPalette.error.main, hover: colorPalette.error.dark, disabled: 'transparent', opacity: 0.7 },
      button: { base: colorPalette.error.main, hover: colorPalette.error.dark, disabled: 'transparent' },
    };
  }
  return {
    icon: { base: colorPalette[color][50], hover: colorPalette[color][100], disabled: colorPalette.grey[200], opacity: 1 },
    button: { base: colorPalette[color][100], hover: colorPalette[color][200], disabled: colorPalette.grey[200] },
  };
};

const useStyles = makeStyles(theme => ({
  button: {
    textTransform: ({ textLike }) => (textLike ? 'none' : 'uppercase'),
    background: ({ customColor }) => colorResolver(customColor, theme.palette).icon.base,
    borderColor: ({ customColor }) => {
      let result;
      try {
        result = darken(colorResolver(customColor, theme.palette).icon.base, 0.4);
      } catch (e) {
        result = 'unset';
      }
      return result;
    },
    '&:hover': {
      background: ({ customColor }) => colorResolver(customColor, theme.palette).icon.hover,
    },
    '& .MuiSvgIcon-root': {
      fill: theme.palette.secondary[900],
    },
    '&.MuiIconButton-root': {
      padding: theme.spacing(1),
    },

    '&.MuiButton-root': {
      padding: ({ textLike }) => (textLike ? '0' : '12px'),
      background: ({ customColor, textLike }) => (textLike ? 'transparent' : colorResolver(customColor, theme.palette).button.base),
      color: ({ customColor, textLike }) => textLike && colorResolver(customColor, theme.palette).button.base,
      '&:hover': {
        padding: ({ textLike }) => (textLike ? '0' : '12px'),
        background: ({ customColor, textLike }) => (textLike ? 'transparent' : colorResolver(customColor, theme.palette).button.hover),
        color: ({ customColor, textLike }) => textLike && colorResolver(customColor, theme.palette).button.hover,
      },
      '&.Mui-disabled': {
        background: ({ customColor, textLike }) => (textLike ? 'transparent' : colorResolver(customColor, theme.palette).button.disabled),
        color: ({ customColor, textLike }) => textLike && colorResolver(customColor, theme.palette).button.disabled,
        padding: ({ textLike }) => (textLike ? '0' : '12px'),
      },
    },
  },
}));

const ColoredButton = ({ customColor, className, icon, showLoader, children, variant, disabled, textLike, ...props }) => {
  const styles = useStyles({ customColor, textLike });
  const Component = icon ? IconButton : Button;
  return (
    <Component
      className={clsx(styles.button, className)}
      color={customColor}
      data-testid='colored-button'
      disabled={disabled || showLoader}
      variant={textLike ? 'text' : variant}
      {...props}
    >
      {showLoader ? <CircularProgress color={customColor} size={24} /> : children}
    </Component>
  );
};

ColoredButton.propTypes = {
  customColor: PropTypes.oneOf(['primary', 'secondary', 'grey', 'none', 'danger']).isRequired,
  className: PropTypes.string,
  icon: PropTypes.bool,
  textLike: PropTypes.bool,
  showLoader: PropTypes.bool,
  children: PropTypes.node.isRequired,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
};

ColoredButton.defaultProps = {
  className: null,
  icon: false,
  showLoader: false,
  disabled: false,
  textLike: false,
  variant: 'outlined',
};

export default ColoredButton;

import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  container: {
    display: 'flex',
    gap: theme.spacing(4),
    flexWrap: 'wrap',
    paddingBottom: theme.spacing(4),
  },
}));

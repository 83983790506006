import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  stickyContainer: {
    position: 'sticky',
    zIndex: 10,
    top: 0,
  },
  noSelected: {
    padding: theme.spacing(2, 0),
  },
  container: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2, 0),

    // @ts-ignore
    borderBottom: `1px solid ${theme.palette.secondary[500]}`,

    display: 'flex',
    gap: theme.spacing(2),
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  element: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
    '& p': {
      fontVariantNumeric: 'tabular-nums',
    },
  },
}));

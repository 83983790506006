import axios from 'axios';

import convertObjectToFormData from 'helpers/convertObjectToFormData';
import createUrlWithParams from 'helpers/createUrlWithParams';

import METHOD from '../_methods';
import { BasePaginatedQueryParams } from '../_types/BasePaginatedQueryParams';

import {
  parseSingleManagementReportsForFE,
  parseAllManagementReportsForFE,
  parseManagementReportPartialUpdate,
  parseManagementReportSecurityPartialUpdate,
} from './managementReports.parsers';
import type ManagementReportsApi from './managementReports.types';

const endpoint = 'api/v1/management_reports/';

export const legacyManagementReportsApi = {
  getAllManagementReports: (params: BasePaginatedQueryParams) => ({
    url: createUrlWithParams(endpoint, params),
    method: METHOD.GET,
  }),
};

const managementReportsApi: ManagementReportsApi = {
  getAllManagementReports: async () => {
    const { data } = await axios.get(endpoint);
    return parseAllManagementReportsForFE(data);
  },
  getSingleManagementReport: async id => {
    const { data } = await axios.get(`${endpoint}${id}/`);
    return parseSingleManagementReportsForFE(data);
  },
  removeManagementReport: id => axios.delete(`${endpoint}${id}/`),
  createManagementReport: formData => {
    const data = parseManagementReportPartialUpdate(formData);
    return axios.post(endpoint, data);
  },
  partialUpdateManagementReport: id => async formData => {
    const data = parseManagementReportPartialUpdate(formData);
    return axios.patch(`${endpoint}${id}/`, data);
  },
  partialUpdateManagementReportSecurity: id => async formData => {
    const data = convertObjectToFormData(parseManagementReportSecurityPartialUpdate(formData));
    return axios.patch(`${endpoint}${id}/security_report/`, data);
  },
};

export default managementReportsApi;

import React, { useEffect, useMemo } from 'react';

import { CircularProgress, Divider, TextField, Typography } from '@material-ui/core';
import { format, isEqual } from 'date-fns';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import PubSub from 'pubsub-js';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import * as yup from 'yup';

import CenteredGrid from 'components/CenteredGrid/CenteredGrid';
import ColoredButton from 'components/ColoredButton';
import FormAutocomplete from 'components/FormAutocomplete';
import FormDateInput from 'components/FormDateInput';
import FormSelect from 'components/FormSelect';
import FormTextInput from 'components/FormTextInput';
import TypographyWithHTML from 'components/TypographyWithHTML/TypographyWithHTML';
import UserAvatarWithDescription from 'components/UserAvatarWithDescription/UserAvatarWithDescription';
import { AssetRisk } from 'config/api/assetRisks/assetRisks.types';
import assetRiskTasksApi from 'config/api/assetRiskTasks/assetRiskTasks';
import { AssetRiskEndpoints, CreateInputForm, AssetRiskTasks } from 'config/api/assetRiskTasks/assetRiskTasks.types';
import QUERY_KEYS from 'config/api/QUERY_KEYS';
import DATE_FORMAT from 'config/constants/DATE_FORMAT';
import TASK_STATUSES_SELECT from 'config/dictionaries/TASK_STATUSES_SELECT';
import EVENTS from 'config/events/pubsub';
import safeT from 'helpers/safeT/safeT';
import asset_risk_messages from 'messages/asset_risk_messages';
import general_messages from 'messages/general_messages';
import validation_messages from 'messages/validation_messages';
import coworkerOptionsParser from 'services/autocompleteServices/coworkerOptionsParser/coworkerOptionsParser';
import coworkerParamsGetter from 'services/autocompleteServices/coworkerParamsGetter/coworkerParamsGetter';
import coworkerResultComponent from 'services/autocompleteServices/coworkerResultComponent/coworkerResultComponent';
import { Id } from 'types/Id';

import ButtonsGrid from '../_components/ButtonsGrid';
import DialogGrid from '../_components/DialogGrid';

import useStyles from './AssetRiskTaskDialog.styles';

type Props = {
  open: boolean;
  onClose: () => void;
  previewMode: boolean;
  taskId: Id | null;
  risk: AssetRisk | null;
  endpoint?: AssetRiskEndpoints;
  disableBaseData?: boolean;
};

type FormType = Omit<CreateInputForm, 'risk'>;

const getDiff = (final: FormType, initial?: AssetRiskTasks): Partial<FormType> => {
  if (!initial) return final;
  const diff: Partial<FormType> = {};
  if (initial.comment !== final.comment) diff.comment = final.comment;
  if (!isEqual(new Date(initial.dueDate), new Date(final.dueDate))) diff.dueDate = final.dueDate;
  if (initial.plannedAction !== final.plannedAction) diff.plannedAction = final.plannedAction;
  if (initial.status !== final.status) diff.status = final.status;
  if (initial.description !== final.description) diff.description = final.description;
  if (initial.assignedTo.id !== final.assignedTo[0].key) diff.assignedTo = final.assignedTo;
  return diff;
};

const AssetRiskTaskDialog: React.FC<Props> = ({ open, onClose, taskId, risk, previewMode, disableBaseData, endpoint = 'general' }) => {
  const FORM_ID = useMemo(() => `AssetRiskTask-${taskId}`, [taskId]);

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { data: initialData, isLoading } = useQuery(
    [QUERY_KEYS.GET_SINGLE_ORGANIZATION_ASSET_RISK_TASK, taskId, endpoint],
    () => assetRiskTasksApi.getSingleAssetRiskTasks(taskId as Id, endpoint),
    {
      enabled: !!taskId,
    },
  );

  const createMutation = useMutation(assetRiskTasksApi.createAssetRiskTask);
  const updateMutation = useMutation(assetRiskTasksApi.updateAssetRiskTask);

  const onSubmit = async (formValues: FormType) => {
    const isUpdate = !!taskId;
    if (isUpdate) {
      const data = getDiff(formValues, initialData);
      await updateMutation.mutateAsync({ id: taskId as Id, data, endpoint });
    } else {
      await createMutation.mutateAsync({ ...formValues, risk: risk?.id as Id });
      PubSub.publish(EVENTS.RISK_ANALYSIS_RISKS_UPDATED);
    }
    enqueueSnackbar(t(general_messages.data_saved), { variant: 'success' });
    PubSub.publish(EVENTS.RISK_ANALYSIS_TASK_UPDATED);
    onClose();
  };

  const form = useFormik<FormType>({
    initialValues: {
      comment: initialData?.comment || '',
      dueDate: initialData?.dueDate || new Date(),
      plannedAction: initialData?.plannedAction || '',
      status: initialData?.status || 'not_started',
      description: initialData?.description || '',
      assignedTo: initialData?.assignedTo ? [{ key: initialData.assignedTo.id, fullName: initialData.assignedTo.profile.fullName }] : [],
    },
    onSubmit,
    validationSchema: yup.object({
      dueDate: yup.date().required(t(validation_messages.required)),
      plannedAction: yup.string().required(t(validation_messages.required)),
      status: yup.string().required(t(validation_messages.required)),
      description: yup.string().required(t(validation_messages.required)),
      assignedTo: yup.array().min(1, t(validation_messages.required)),
    }),
  });

  useEffect(() => {
    if (initialData) {
      form.setValues({
        comment: initialData.comment,
        status: initialData.status,
        dueDate: initialData.dueDate,
        plannedAction: initialData.plannedAction,
        description: initialData.description,
        assignedTo: initialData.assignedTo ? [{ key: initialData.assignedTo.id, fullName: initialData.assignedTo.profile.fullName }] : [],
      });
    }
  }, [initialData]);

  const title = useMemo(() => {
    const getBase = () => {
      if (previewMode) {
        return t(asset_risk_messages.risk_task_dialog.preview_title);
      }
      return taskId ? t(asset_risk_messages.risk_task_dialog.edit_title) : t(asset_risk_messages.risk_task_dialog.create_title);
    };
    const base = getBase();

    return initialData ? `${base} - ${initialData?.risk.name}` : base;
  }, [previewMode, taskId, t, initialData]);

  const styles = useStyles();

  return (
    // @ts-ignore
    <DialogGrid
      dialogActions={
        previewMode ? (
          <CenteredGrid withoutPadding>
            {/* @ts-ignore */}
            <ColoredButton customColor='secondary' onClick={onClose} variant='outlined'>
              {t(general_messages.close)}
            </ColoredButton>
          </CenteredGrid>
        ) : (
          <ButtonsGrid>
            {/* @ts-ignore */}
            <ColoredButton customColor='none' onClick={onClose} variant='outlined'>
              {t(general_messages.cancel)}
            </ColoredButton>
            {/* @ts-ignore */}
            <ColoredButton customColor='secondary' form={FORM_ID} type='submit' variant='outlined'>
              {t(general_messages.save)}
            </ColoredButton>
          </ButtonsGrid>
        )
      }
      noPadding
      onClose={onClose}
      open={open}
      sending={createMutation.isLoading || updateMutation.isLoading}
      title={title}
    >
      {isLoading ? (
        <CenteredGrid>
          <CircularProgress />
        </CenteredGrid>
      ) : (
        <form id={FORM_ID} onSubmit={form.handleSubmit}>
          <CenteredGrid gridGap={3}>
            {initialData && (
              <>
                <div className={styles.previewSection}>
                  <Typography variant='h4'>{t(asset_risk_messages.risk_task_dialog.asset_name_label)}</Typography>
                  <Typography>{initialData.risk.riskAnalysis.asset.name || t(general_messages.no_data)}</Typography>
                </div>
                <div className={styles.previewSection}>
                  <Typography variant='h4'>{t(asset_risk_messages.risk_task_dialog.asset_type_label)}</Typography>
                  <Typography>
                    {safeT(
                      t,
                      initialData.risk.riskAnalysis.asset.category.nameTranslationKey,
                      initialData.risk.riskAnalysis.asset.category.name,
                    ) || t(general_messages.no_data)}
                  </Typography>
                </div>
                <div className={styles.previewSection}>
                  <Typography variant='h4'>{t(asset_risk_messages.risk_task_dialog.asset_owner_label)}</Typography>
                  <UserAvatarWithDescription
                    description={[
                      initialData.risk.riskAnalysis.asset.owner.profile.fullName,
                      initialData.risk.riskAnalysis.asset.owner.email,
                    ]}
                    userAvatarProps={{
                      size: 'm',
                      userData: initialData.risk.riskAnalysis.asset.owner.profile,
                      userId: initialData.risk.riskAnalysis.asset.owner.id,
                    }}
                  />
                </div>
                <div className={styles.previewSection}>
                  <Typography variant='h4'>{t(asset_risk_messages.risk_task_dialog.analysis_date_label)}</Typography>
                  <Typography>{format(initialData.risk.riskAnalysis.date, DATE_FORMAT.defaultDate)}</Typography>
                </div>
                <div className={styles.previewSection}>
                  <Typography variant='h4'>{t(asset_risk_messages.risk_task_dialog.risk_description_label)}</Typography>
                  <TypographyWithHTML>{initialData.risk.description}</TypographyWithHTML>
                </div>
                <Divider />
              </>
            )}
            {previewMode && initialData ? (
              <>
                <div className={styles.previewSection}>
                  <Typography variant='h4'>{t(asset_risk_messages.risk_task_dialog.planned_action_label)}</Typography>
                  <Typography>{initialData.plannedAction || t(general_messages.no_data)}</Typography>
                </div>
                <div className={styles.previewSection}>
                  <Typography variant='h4'>{t(asset_risk_messages.risk_task_dialog.description_label)}</Typography>
                  <TypographyWithHTML>{initialData.description || t(general_messages.no_data)}</TypographyWithHTML>
                </div>
                <div className={styles.previewSection}>
                  <Typography variant='h4'>{t(asset_risk_messages.risk_task_dialog.status_label)}</Typography>
                  <Typography>{t(asset_risk_messages.risk_task_status[initialData.status])}</Typography>
                </div>
                <div className={styles.previewSection}>
                  <Typography variant='h4'>{t(asset_risk_messages.risk_task_dialog.due_date_label)}</Typography>
                  <Typography>{format(initialData.dueDate, DATE_FORMAT.defaultDate)}</Typography>
                </div>
                <div className={styles.previewSection}>
                  <Typography variant='h4'>{t(asset_risk_messages.risk_task_dialog.assigned_to_label)}</Typography>
                  <UserAvatarWithDescription
                    description={[initialData.assignedTo.profile.fullName, initialData.assignedTo.profile.email]}
                    userAvatarProps={{
                      size: 'm',
                      userData: initialData.assignedTo.profile,
                      userId: initialData.assignedTo.id,
                    }}
                  />
                </div>
                <div className={styles.previewSection}>
                  <Typography variant='h4'>{t(asset_risk_messages.risk_task_dialog.comment_label)}</Typography>
                  <Typography>{initialData.comment || t(general_messages.no_data)}</Typography>
                </div>
              </>
            ) : (
              <>
                {risk && <TypographyWithHTML variant='body2'>{risk.description}</TypographyWithHTML>}
                <FormTextInput
                  disabled={disableBaseData}
                  formik={form}
                  id='plannedAction'
                  label={t(asset_risk_messages.risk_task_dialog.planned_action_label)}
                />
                <FormTextInput
                  disabled={disableBaseData}
                  formik={form}
                  id='description'
                  label={t(asset_risk_messages.risk_task_dialog.description_label)}
                  multiline
                  rows={3}
                />
                <FormSelect
                  formik={form}
                  id='status'
                  label={t(asset_risk_messages.risk_task_dialog.status_label)}
                  options={TASK_STATUSES_SELECT}
                />
                <FormDateInput
                  dateTime={false}
                  disabled={disableBaseData}
                  formik={form}
                  id='dueDate'
                  label={t(asset_risk_messages.risk_task_dialog.due_date_label)}
                />
                {disableBaseData ? (
                  <TextField
                    disabled
                    label={t(asset_risk_messages.risk_task_dialog.assigned_to_label)}
                    value={initialData?.assignedTo.profile.fullName}
                    variant='outlined'
                  />
                ) : (
                  <FormAutocomplete
                    apiCallParamsGetter={coworkerParamsGetter}
                    customizeLabel={coworkerResultComponent}
                    formik={form}
                    id='assignedTo'
                    label={t(asset_risk_messages.risk_task_dialog.assigned_to_label)}
                    multiple={false}
                    optionsParser={coworkerOptionsParser}
                  />
                )}
                <FormTextInput
                  formik={form}
                  id='comment'
                  label={t(asset_risk_messages.risk_task_dialog.comment_label)}
                  multiline
                  rows={3}
                />
              </>
            )}
          </CenteredGrid>
        </form>
      )}
    </DialogGrid>
  );
};

export default AssetRiskTaskDialog;

const general_messages = {
  description: ['general>>description', 'Description'],
  tags: ['general>>labels>>tags', 'Tags'],
  related_content: ['general>>labels>>related_content', 'Related content'],
  save: ['general>>labels>>save', 'Save'],
  remove_from_saved: ['general>>labels>>remove_from_saved', 'Remove bookmark'],
  save_continue: ['general>>labels>>save_continue', 'Save and continue'],
  save_close: ['general>>labels>>save_close', 'Save and close'],
  close: ['general>>labels>>close', 'Close'],
  data_saved: ['general>>data_saved', 'Data saved'],
  published: ['general>>published', 'Published'],
  change_password: ['general>>change_password', 'Change password'],
  publish: ['general>>labels>>publish', 'Publish'],
  no_data: ['general>>no_data', 'No data'],
  guide_me: ['general>>guide_me', 'Guide me I have an accident'],
  hide: ['general>>hide', 'Hide'],
  link_copied: ['general>>link_copied', 'Link copied to clipboard'],
  clear_all: ['general>>clear_all', 'Clear all'],
  comments: ['general>>comments', 'Comments'],
  api_error: ['general>>notifications>>api_error', 'Something went wrong'],
  next_step: ['general>>next_step', 'Next step'],
  article: ['general>>article', 'Article'],
  guide: ['general>>guide', 'Guide'],
  self_test: ['general>>self_test', 'Self Test'],
  incident_guide: ['general>>incident_guide', 'Incident guide'],
  incident_report: ['general>>incident_report', 'Incident report'],
  incident_report_plural: ['general>>incident_report_plural', 'Incident reports'],
  save_progress: ['general>>save_progress', 'Save progress'],
  show_more: ['general>>show_more', 'Show more'],
  new_guide_action: ['general>>new>>guide_action', 'New action'],
  edit_guide_action: ['general>>edit>>guide_action', 'Edit action'],
  go_to_assessment: ['general>>go_to_assessment', 'Start Assessment'],
  go_to_guide: ['general>>go_to_guide', 'Start Guide'],
  edit: ['general>>edit', 'Edit'],
  edit_personal_info: ['general>>edit_personal_info', 'Edit personal info'],
  edit_guide: ['general>>edit>>guide', 'Edit guide'],
  edit_article: ['general>>edit>>article', 'Edit article'],
  upload_image: ['general>>upload_image', 'Upload link image'],
  upload_file: ['general>>upload_file', 'Upload file'],
  upload_file_blocked_info: ['general>>upload_file_blocked_info', 'You must remove existing file before uploading new one'],
  remove_file: ['general>>remove_file', 'Remove file'],
  add_label: ['general>>add_label', 'Add label'],
  metadata: ['general>>labels>>metadata', 'Meta data'],
  search_add: ['general>>labels>>search_add', 'Search and add'],
  new: ['common>>new', 'New'],
  cancel: ['common>>cancel', 'Cancel'],
  ok: ['common>>ok', 'Ok'],
  send: ['common>>send', 'Send'],
  previous: ['common>>previous', 'Previous'],
  next: ['common>>next', 'Next'],
  no: ['common>>no', 'No'],
  yes: ['common>>yes', 'Yes'],
  minutes_shortcut: ['common>>minutes_shortcut', 'MIN'],
  something_went_wrong: ['common>>something_went_wrong', 'Sorry, something went wrong. Try to refresh page.'],
  audience: ['filters>>audience', 'Audience'],
  customer_level: ['filters>>customer', 'Customer level'],
  depth_level: ['filters>>depth', 'Depth level'],
  service_area: ['filters>>service_area', 'Service area'],
  add: ['general>>add', 'Add'],
  attach_file: ['general>>attach_file', 'Attach file'],
  name: ['general>>name', 'Name'],
  first_name: ['general>>first_name', 'First name'],
  last_name: ['general>>last_name', 'Last name'],
  full_name: ['general>>full_name', 'Name and surname'],
  email: ['general>>email', 'Email'],
  phone: ['general>>phone', 'Phone number'],
  update: ['general>>update', 'Update'],
  delete: ['general>>delete', 'Delete'],
  copy_link: ['general>>copy', 'Copy link'],
  information: ['general>>information', 'Information'],
  languages: {
    en: ['languages>>en', 'English'],
    sv: ['languages>>sv', 'Swedish'],
  },
  customer_levels: {
    free: ['article>>choice>>free', 'Free'],
    standard: ['article>>choice>>standard', 'Standard'],
    premium: ['article>>choice>>premium', 'Premium'],
  },
  deep_levels: {
    flyover: ['article>>choice>>flyover', 'Flyover'],
    base: ['article>>choice>>base', 'Base'],
    'in-dept': ['article>>choice>>in-dept', 'In dept'],
  },
  audiences: {
    private_individuals: ['article>>choice>>private_individuals', 'Private individuals'],
    organizations: ['article>>choice>>organizations', 'Organizations'],
    both: ['article>>choice>>both', 'Both'],
  },
  user_statuses: {
    active: ['general>>user_statuses>>active', 'Active'],
    not_verified: ['general>>user_statuses>>not_verified', 'Not verified'],
  },
  contents: ['general>>contents', 'Contents'],
  content_types: {
    article: ['general>>content_types>>article', 'Article'],
    articles: ['general>>content_types>>articles', 'Articles'],
    guide: ['general>>content_types>>guide', 'Guide'],
    incident_guide: ['general>>content_types>>incident_guide', 'Incident guide'],
    incident_report: ['general>>content_types>>incident_report', 'Incident report'],
    guides: ['general>>content_types>>guides', 'Guides'],
    self_test: ['general>>content_types>>self_test', 'Self test'],
    self_tests: ['general>>content_types>>self_tests', 'Self tests'],
    assessment: ['general>>content_types>>assessment', 'Assessment'],
    assessments: ['general>>content_types>>assessments', 'Assessments'],
  },
  content_type: ['general>>content_type', 'Content type'],
  today: ['general>>today', 'Today'],
  tomorrow: ['general>>tomorrow', 'Tomorrow'],
  date_and_time: ['general>>date_and_time', 'Date and time'],
  not_found_title: ['general>>not_found_title', 'Page not found'],
  not_found_description: ['general>>not_found_description', `We couldn't find the page you are looking for`],
  back_to_home: ['general>>back_to_home', 'Back to the homepage'],
  unauthorized_title: ['general>>unauthorized_title', 'Unauthorized'],
  unauthorized_description: ['general>>unauthorized_description', `You don't have access to this resources.`],
  unsaved_changes_title: ['general>>unsaved_changes_title', 'Unsaved changes'],
  unsaved_changes_body: ['general>>unsaved_changes_body', 'You will lose all provided changes, continue?'],
  service_area_redirect_button_label: ['general>>service_area_redirect_button_label', 'See all {{service_area}} {{content_type}}'],
  active_content: ['general>>active_content', 'Content is active and searchable'],
  guide_buttons: {
    save_draft: ['guide_buttons>>save_draft', 'Save draft'],
    publish: ['guide_buttons>>publish', 'Apply'],
  },
  business_types: {
    agriculture_forest_fish: ['general>>business_types>>agriculture_forest_fish', 'Agriculture, forestry and fishing'],
    mining_quarrying: ['general>>business_types>>mining_quarrying', 'Mining and quarrying'],
    manufacturing: ['general>>business_types>>manufacturing', 'Manufacturing'],
    electricity_gas_conditioning: [
      'general>>business_types>>electricity_gas_conditioning',
      'Electricity, gas, steam and air conditioning supply',
    ],
    water_supply: ['general>>business_types>>water_supply', 'Water supply'],
    construction: ['general>>business_types>>construction', 'Construction'],
    wholesale_retail_trade: ['general>>business_types>>wholesale_retail_trade', 'Wholesale and retail trade'],
    transportation_storage: ['general>>business_types>>transportation_storage', 'Transportation and storage'],
    accommodation_food: ['general>>business_types>>accommodation_food', 'Accommodation and food service activities'],
    information_communication: ['general>>business_types>>information_communication', 'Information and communication'],
    financial_insurance: ['general>>business_types>>financial_insurance', 'Financial and insurance activities'],
    real_estate: ['general>>business_types>>real_estate', 'Real estate activities'],
    scientific_technical: ['general>>business_types>>scientific_technical', 'Professional, scientific and technical activities'],
    administrative_support_service: [
      'general>>business_types>>administrative_support_service',
      'Administrative and support service activities',
    ],
    public_administration_defence: ['general>>business_types>>public_administration_defence', 'Public administration and defence'],
    education: ['general>>business_types>>education', 'Education'],
    human_health_social_work: ['general>>business_types>>human_health_social_work', 'Human health and social work activities'],
    arts_entertainment_recreation: ['general>>business_types>>arts_entertainment_recreation', 'Arts, entertainment and recreation'],
    other_service: ['general>>business_types>>other_service', 'Other service activities'],
    households_as_employers: ['general>>business_types>>households_as_employers', 'Activities of households as employers'],
    extraterritorial_bodies: [
      'general>>business_types>>extraterritorial_bodies',
      'Activities of extraterritorial organisations and bodies',
    ],
  },
  employees_number: {
    one_man: ['general>>employees_number>>one_man', 'One man company (0)'],
    micro: ['general>>employees_number>>micro', 'Micro companies (1-9)'],
    small: ['general>>employees_number>>small', 'Small companies(10-49)'],
    medium: ['general>>employees_number>>medium', 'Mid sized companies (50-249)'],
    large: ['general>>employees_number>>large', 'large companies (250+)'],
  },
  delete_with_ctrl: ['general>>delete_with_ctrl', 'Hold ctrl or command button to remove instantly'],
  language: ['general>>language', 'Language'],
  publish_status: ['general>>publish_statuses', 'Publish status'],
  publish_statuses: {
    published: ['general>>publish_statuses>>published', 'Published'],
    inactive: ['general>>publish_statuses>>inactive', 'Inactive'],
    under_revision: ['general>>publish_statuses>>under_revision', 'Under revision'],
    new: ['general>>publish_statuses>>new', 'New'],
  },
  email_readonly: ['general>>email_readonly', 'Please contact Pocketsafe support in order to change email'],
  advisor: ['general>>advisor', 'Advisor'],
  back: ['general>>back', 'Back'],
  confirm: ['general>>confirm', 'Confirm'],
  date: ['general>>date', 'Date'],
  cookie_consent: [
    'general>>cookie_consent',
    'We use cookies on this site to improve performance. By browsering this site you are agreeing to this. For more information see our',
  ],
  cookie_privacy_policy: ['general>>cookie_privacy_policy', 'Privacy policy'],
  understand: ['general>>understand', 'I understand'],
  content_copied: ['general>>content_copied', 'Content copied to clipboard'],
  days_suffix: ['general>>days_suffix', '{{days}} days'],
  hours_suffix: ['general>>hours_suffix', '{{hours}} hours'],
  how_it_works: ['general>>how_it_works', 'How it works?'],
  month: ['general>>month', 'Month'],
  day: ['general>>day', 'Day'],
  not_applicable: ['general>>not_applicable', 'Not applicable'],
  print: ['general>>print', 'Print'],
  are_you_sure: ['general>>are_you_sure', 'Are you sure?'],
  operation_irreversible: ['general>>operation_irreversible', 'That operation is irreversible.'],
  created_at: ['general>>created_at', 'Created at'],
  updated_at: ['general>>updated_at', 'Updated at'],
  loading: ['general>>loading', 'Loading...'],
  data_removed: ['general>>data_removed', 'Element has been removed'],
};

export default general_messages;

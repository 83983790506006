import axios from 'axios';

import createUrlWithParams from 'helpers/createUrlWithParams';
import { Id } from 'types/Id';

import METHOD from '../_methods';
import { BasePaginatedQueryParams } from '../_types/BasePaginatedQueryParams';

import { parseSingleAssetRisksForFE, parseAllAssetRisksForFE, parseDataForCreateAsset } from './assetRisks.parsers';
import AssetRisksApi, { AssetRiskImportance, RiskPlace, RiskScaleValue } from './assetRisks.types';

const endpoint = 'api/v1/asset_risks/';

type GetAllParams = {
  affect_individuals?: boolean;
  management_report?: Id;
  place?: RiskPlace;
  primary_consequence?: string;
  risk_analysis?: string | number;
  risk_analysis__asset__info__assets_importance?: AssetRiskImportance;
  risk_quadrant?: RiskScaleValue;
};

export const legacyAssetRisksApi = {
  getAllAssetRisks: (staticParams?: GetAllParams) => (params: BasePaginatedQueryParams) => ({
    url: createUrlWithParams(endpoint, { ...params, ...(staticParams || {}) }),
    method: METHOD.GET,
  }),
};

const assetRisksApi: AssetRisksApi = {
  getAllAssetRisks: async params => {
    const { data } = await axios.get(createUrlWithParams(endpoint, { limit: params.limit || 100, ...params }));
    return parseAllAssetRisksForFE(data.results);
  },
  getSingleAssetRisk: async id => {
    const { data } = await axios.get(`${endpoint}${id}/`);
    return parseSingleAssetRisksForFE(data);
  },
  createAssetRisk: formInput => axios.post(endpoint, parseDataForCreateAsset(formInput)),
  updateAssetRisk: riskId => formInput => axios.put(`${endpoint}${riskId}/`, parseDataForCreateAsset(formInput)),
  removeAssetRisk: riskId => axios.delete(`${endpoint}${riskId}/`),
};

export default assetRisksApi;

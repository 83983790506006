import axios from 'axios';

import createUrlWithParams from 'helpers/createUrlWithParams';

import METHOD from '../_methods';
import { BasePaginatedQueryParams } from '../_types/BasePaginatedQueryParams';

import { parseOrganizationRiskAnalysesForFE } from './organizationRiskAnalyses.parsers';
import type ActiveUserRiskAnalysesAssetsForCreateApi from './organizationRiskAnalyses.types';

const endpoint = 'api/v1/organization/risk_analyses/';

export const organizationRiskAnalysesApiLegacy = {
  getOrganizationRiskAnalyses: (params: BasePaginatedQueryParams) => ({
    url: createUrlWithParams(endpoint, params),
    method: METHOD.GET,
  }),
};

const organizationRiskAnalysesApi: ActiveUserRiskAnalysesAssetsForCreateApi = {
  getOrganizationRiskAnalyses: async () => {
    const { data } = await axios.get(endpoint);
    return parseOrganizationRiskAnalysesForFE(data);
  },
};

export default organizationRiskAnalysesApi;

import React, { FC, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import FooterButton from 'components/FooterButton';
import Section from 'components/Section';
import Table from 'components/Table';
import TextButton from 'components/TextButton/TextButton';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import { legacyOrganizationAssetTasksApi } from 'config/api/organizationAssetTasks/organizationAssetTasks';
import { parseAllOrganizationAssetTasksForFE } from 'config/api/organizationAssetTasks/organizationAssetTasks.parsers';
import { OrganizationAssetTasksInList } from 'config/api/organizationAssetTasks/organizationAssetTasks.types';
import pubsub from 'config/events/pubsub';
import safeT from 'helpers/safeT/safeT';
import usePagination from 'hooks/usePagination';
import usePrepareAvailableFilters from 'hooks/usePrepareAvailableFilters/usePrepareAvailableFilters';
import useSubscription from 'hooks/useSubscription';
import assets_messages from 'messages/assets_messages';
import general_messages from 'messages/general_messages';
import manage_dashboard_messages from 'messages/manage_dasboard_messages';
import { useHelpDialogContext } from 'reactContext/HelpDialogContext/HelpDialogContext';
import assetsImportanceFilter from 'services/_filters/parsers/_assetsFilters/assetsImportanceFilter';
import assetsTaskActivityFilterFactory from 'services/_filters/parsers/_assetTasksFilters/assetsTaskActivityFilterFactory';
import assetTaskStatusFilter from 'services/_filters/parsers/_assetTasksFilters/assetTaskStatusFilter';
import parseFiltersForRequest from 'services/parseFiltersForRequest';
import { Id } from 'types/Id';
import { TableDataMappingRow } from 'types/Table';

import useGetManagementReportBaseData from '../../_hooks/useGetManagementReportBaseData';
import ManagementCommentInput from '../ManagementCommentInput/ManagementCommentInput';
import ManagementDashboardSectionGrid from '../ManagementDashboardSectionGrid/ManagementDashboardSectionGrid';

type Props = {
  reportId?: Id;
};

const ManagementDashboardPlannedActivitiesSection: FC<Props> = ({ reportId }) => {
  const { t } = useTranslation();
  const { showHelpDialog } = useHelpDialogContext();

  const showIntroductionDialog = () => {
    showHelpDialog({
      title: t(manage_dashboard_messages.planned_activities_section.introduction_title),
      body: t(manage_dashboard_messages.planned_activities_section.introduction_body),
    });
  };

  const { data } = useGetManagementReportBaseData(reportId);

  const availableFilters = usePrepareAvailableFilters({
    status: assetTaskStatusFilter,
    asset__info__assets_importance: assetsImportanceFilter,
    year_planner_activity__activity: assetsTaskActivityFilterFactory('assetTasks'),
  });

  const [sort, setSort] = useState<{ key: string | null; direction: string | null }>({ key: null, direction: null });
  const onSort = (key: string, direction: string) => setSort({ key, direction });
  const [filters, setFilters] = useState({});

  const {
    data: tableData,
    getData: getTableData,
    hasNextPage,
    loading,
    initialLoading,
    refreshData,
  } = usePagination({
    endpointFunc: legacyOrganizationAssetTasksApi.getAllOrganizationAssetTasks({ management_report: reportId }),
    limits: [5, 5, 5],
    isGet: true,
    dependencies: [sort, filters],
    sort,
    filters: parseFiltersForRequest(filters, {}, availableFilters || {}),
  });

  useSubscription(pubsub.SELECTED_MANAGEMENT_REPORTS_UPDATED, refreshData);

  const dataMapping: TableDataMappingRow<OrganizationAssetTasksInList>[] = useMemo(
    () => [
      {
        label: t(assets_messages.asset_tasks_table.asset_name),
        get: ({ asset }) => asset.name,
        id: 'asset__name',
        width: '20%',
      },
      {
        label: t(assets_messages.asset_tasks_table.asset_category),
        get: ({ asset }) => safeT(t, asset.category.nameTranslationKey, asset.category.name),
        id: 'asset__category__translated_name',
        width: '20%',
      },
      {
        label: t(assets_messages.asset_tasks_table.owner),
        get: ({ asset }) => <UserAvatar lazy showName userData={asset.owner.profile} userId={asset.owner.id} />,
        id: 'asset__owner__full_name',
        width: '20%',
      },
      {
        label: t(assets_messages.asset_tasks_table.activity),
        get: ({ yearPlannerActivity }) => safeT(t, yearPlannerActivity.activity.nameTranslationKey, yearPlannerActivity.activity.name),
        id: 'year_planner_activity__activity__translated_name',
        width: '25%',
      },
      {
        label: t(assets_messages.asset_tasks_table.status),
        get: ({ status }) => t(assets_messages.asset_task_status[status]),
        id: 'status',
        width: '15%',
      },
    ],
    [],
  );

  return (
    <Section
      title={manage_dashboard_messages.planned_activities_section.title}
      titleLineAddon={[
        <TextButton key='introduction' onClick={showIntroductionDialog}>
          {t(manage_dashboard_messages.planned_activities_section.introduction_button)}
        </TextButton>,
      ]}
    >
      <ManagementDashboardSectionGrid>
        <div>
          <Table
            data={tableData && parseAllOrganizationAssetTasksForFE(tableData)}
            dataMapping={dataMapping}
            filtersService={{ availableFilters, filters, setFilters }}
            fixedHeader
            onSort={onSort}
            refetching={loading && !initialLoading}
            sort={sort}
          />
          {hasNextPage && (
            // @ts-ignore
            <FooterButton loading={loading} onClick={getTableData}>
              {t(general_messages.show_more)}
            </FooterButton>
          )}
        </div>
        <ManagementCommentInput fieldKey='assetTasksReport' initialValue={data?.assetTasksReport?.comment || ''} reportId={reportId} />
      </ManagementDashboardSectionGrid>
    </Section>
  );
};

export default ManagementDashboardPlannedActivitiesSection;

import { useQuery } from 'react-query';

import managementReportsApi from 'config/api/managementReports/managementReports';
import QUERY_KEYS from 'config/api/QUERY_KEYS';
import { Id } from 'types/Id';

const useGetManagementReportBaseData = (id?: Id | null) =>
  useQuery([QUERY_KEYS.GET_MANAGEMENT_REPORT, id], () => managementReportsApi.getSingleManagementReport(id!), {
    enabled: !!id,
  });

export default useGetManagementReportBaseData;

import React from 'react';

import { format } from 'date-fns';

import UserAvatar from 'components/UserAvatar/UserAvatar';
import { questionStatusResolver } from 'config/translatableConstants/TRANSLATABLE_QUESTION_STATUSES';
import questions_messages from 'messages/questions_messages';

const getDataMappingForQuestions = t => [
  {
    id: 'knowledge_area_name',
    width: '18%',
    label: t(...questions_messages.table_header.knowledge_area),
    get: data => data?.knowledge_area_name,
  },
  {
    id: 'text',
    width: '40%',
    label: t(...questions_messages.table_header.question),
    get: data => data?.text,
  },
  {
    id: 'progress_status',
    width: '12%',
    label: t(...questions_messages.table_header.status),
    get: data => data?.progress_status && t(questionStatusResolver(data.progress_status)),
  },
  {
    id: 'created_at',
    width: '12%',
    label: t(...questions_messages.table_header.date),
    get: data => data?.created_at && format(new Date(data.created_at), 'yyyy-MM-dd, HH:mm'),
  },
  {
    id: 'user',
    width: '18%',
    label: t(...questions_messages.table_header.questioned_by),
    get: data => (
      <UserAvatar
        lazy
        showName
        userData={{
          firstName: data?.user_profile.first_name,
          lastName: data?.user_profile.last_name,
          imageUrl: data?.user_profile.image_url,
        }}
        userId={data?.user_id}
      />
    ),
  },
];

export default getDataMappingForQuestions;

import { StaticTableFilter } from 'types/TableFilter';

import booleanDictionary from '../booleanDictionary';

const booleanFilterFactory = (sectionName: string[]): StaticTableFilter => ({
  isDynamic: false,
  sectionName,
  filters: booleanDictionary,
  isSingleSelect: true,
});

export default booleanFilterFactory;

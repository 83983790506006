import React, { createContext, useState, useContext } from 'react';

import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import authEndpoints from 'config/api/auth';
import { endpoint as authTotpDevicesEndpoint } from 'config/api/authTotpDevices/authTotpDevices';
import usersEndpoints, { usersApi } from 'config/api/users/users';
import useApiCall from 'hooks/useApiCall';
import otp_messages from 'messages/otp_messages';
import accessTokenStorage from 'storages/accessTokenStorage';
import refreshTokenStorage from 'storages/refreshTokenStorage';
import userDataStorage from 'storages/userDataStorage';

import { useOTPAuthModalContext } from '../OTPAuthModalContext/OTPAuthModalContext';

const AuthContext = createContext(null);

const { Provider } = AuthContext;
const initialAccessToken = accessTokenStorage.get();
const initialRefreshToken = refreshTokenStorage.get();

const AuthProvider = props => {
  const { apiCall } = useApiCall();
  const { i18n } = useTranslation();
  const { showOTPAuthModal } = useOTPAuthModalContext();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [isAuthorized, setIsAuthorized] = useState(initialAccessToken && initialRefreshToken);
  const langMutation = useMutation('Set language for user', usersApi.setLanguage);

  const updateUserData = async () => {
    const [{ data: currentUser }, { data: me }] = await Promise.all([apiCall(usersEndpoints.currentUser()), apiCall(authEndpoints.me())]);
    if (me.language_code) await i18n.changeLanguage(me.language_code);
    else langMutation.mutate({ language_code: i18n.language });
    userDataStorage.set({ ...currentUser });
  };

  const onLogout = async ({ omitAPICall } = { omitAPICall: false }) => {
    if (!omitAPICall) {
      await axios({ ...authEndpoints.logout(), data: { refresh: refreshTokenStorage.get() } });
    }

    refreshTokenStorage.destroy();
    accessTokenStorage.destroy();
    userDataStorage.destroy();
    axios.defaults.headers.common.Authorization = undefined;
    setIsAuthorized(false);
    return null;
  };

  const onAccessObtained = async ({ access, refresh }) => {
    axios.defaults.headers.common.Authorization = `Bearer ${access}`;
    await updateUserData();
    refreshTokenStorage.set(refresh);
    accessTokenStorage.set(access);
    setIsAuthorized(true);
    return 'success';
  };

  const onLogin = async ({ refresh, access, otp_required }) => {
    if (otp_required) {
      axios.defaults.headers.common.Authorization = `Bearer ${access}`;
      const otpVerificationData = await showOTPAuthModal();

      if (otpVerificationData) {
        try {
          const { data } = await axios.post(`${authTotpDevicesEndpoint}${otpVerificationData.deviceId}/verify_token/`, {
            otp_token: otpVerificationData.otpToken,
          });

          if (data.refresh && data.access) {
            return onAccessObtained({ access: data.access, refresh: data.refresh });
          }
        } catch (e) {
          return null;
        }
      }

      enqueueSnackbar(t(otp_messages.otp_auth_failed), { variant: 'error' });
      return null;
    }
    return onAccessObtained({ access, refresh });
  };

  return <Provider value={{ isAuthorized, updateUserData, onLogin, onLogout }} {...props} />;
};

export const useAuthContext = () => useContext(AuthContext);

export default AuthProvider;

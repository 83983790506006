import React, { FC, useMemo, useState } from 'react';

import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import CarouselWrapper from 'components/CarouselWrapper/CarouselWrapper';
import ColoredTile from 'components/ColoredTile';
import FooterButton from 'components/FooterButton';
import MobileGuttersContainer from 'components/MobileGuttersContainer';
import Section from 'components/Section';
import Table from 'components/Table';
import TextButton from 'components/TextButton/TextButton';
import TrimHTMLText from 'components/TrimHTMLText/TrimHTMLText';
import TypographyWithHTML from 'components/TypographyWithHTML/TypographyWithHTML';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import { legacyAssetRiskTasksApi } from 'config/api/assetRiskTasks/assetRiskTasks';
import { parseAllAssetRiskTasksForFE } from 'config/api/assetRiskTasks/assetRiskTasks.parsers';
import { AssetRiskTasks } from 'config/api/assetRiskTasks/assetRiskTasks.types';
import DATE_FORMAT from 'config/constants/DATE_FORMAT';
import pubsub from 'config/events/pubsub';
import usePagination from 'hooks/usePagination';
import usePrepareAvailableFilters from 'hooks/usePrepareAvailableFilters/usePrepareAvailableFilters';
import useSubscription from 'hooks/useSubscription';
import asset_risk_messages from 'messages/asset_risk_messages';
import asset_risk_tasks_messages from 'messages/asset_risk_tasks_messages';
import general_messages from 'messages/general_messages';
import manage_dashboard_messages from 'messages/manage_dasboard_messages';
import { useHelpDialogContext } from 'reactContext/HelpDialogContext/HelpDialogContext';
import assetsImportanceFilter from 'services/_filters/parsers/_assetsFilters/assetsImportanceFilter';
import affectsIndividualsFilter from 'services/_filters/parsers/_riskAnalysisFilters/affectsIndividualsFilter';
import riskPlaceFilter from 'services/_filters/parsers/_riskAnalysisFilters/riskPlaceFilter';
import riskQuadrantFilter from 'services/_filters/parsers/_riskAnalysisFilters/riskQuadrantFilterFactory';
import parseFiltersForRequest from 'services/parseFiltersForRequest';
import { Id } from 'types/Id';
import { TableDataMappingRow } from 'types/Table';

import useGetManagementReportBaseData from '../../_hooks/useGetManagementReportBaseData';
import ManagementCommentInput from '../ManagementCommentInput/ManagementCommentInput';
import ManagementDashboardSectionGrid from '../ManagementDashboardSectionGrid/ManagementDashboardSectionGrid';

type Props = {
  reportId?: Id;
};

const ManagementDashboardRiskTasksSection: FC<Props> = ({ reportId }) => {
  const { t } = useTranslation();
  const { showHelpDialog } = useHelpDialogContext();

  const showIntroductionDialog = () => {
    showHelpDialog({
      title: t(manage_dashboard_messages.risk_tasks_section.introduction_title),
      body: t(manage_dashboard_messages.risk_tasks_section.introduction_body),
    });
  };

  const { data } = useGetManagementReportBaseData(reportId);

  const kpiData = useMemo(() => {
    const { riskTasksReport } = data || {};
    return [
      {
        description: t(manage_dashboard_messages.risk_tasks_section.not_started_tasks_kpi_label),
        title: riskTasksReport?.notStartedTasks ?? null,
      },
      {
        description: t(manage_dashboard_messages.risk_tasks_section.started_tasks_kpi_label),
        title: riskTasksReport?.startedTasks ?? null,
      },
      {
        description: t(manage_dashboard_messages.risk_tasks_section.overdue_tasks_kpi_label),
        title: riskTasksReport?.overdueTasks ?? null,
      },
    ];
  }, [data, t]);

  const availableFilters = usePrepareAvailableFilters({
    risk__risk_quadrant: riskQuadrantFilter(asset_risk_messages.risk_table.calculated_risk_filter),
    risk__affect_individuals: affectsIndividualsFilter,
    risk__risk_analysis__asset__info__assets_importance: assetsImportanceFilter,
    risk__place: riskPlaceFilter,
  });

  // TABLE LOGIC
  const [sort, setSort] = useState<{ key: string | null; direction: string | null }>({ key: null, direction: null });
  const onSort = (key: string, direction: string) => setSort({ key, direction });
  const [filters, setFilters] = useState({});

  const {
    data: tableData,
    getData: getTableData,
    hasNextPage,
    loading,
    initialLoading,
    refreshData,
  } = usePagination({
    endpointFunc: legacyAssetRiskTasksApi.getAllAssetRiskTasks('organization', { management_report: reportId }),
    limits: [5, 5, 5],
    isGet: true,
    dependencies: [filters],
    sort,
    filters: parseFiltersForRequest(filters, {}, availableFilters || {}),
  });

  useSubscription(pubsub.SELECTED_MANAGEMENT_REPORTS_UPDATED, refreshData);

  const dataMapping: TableDataMappingRow<AssetRiskTasks>[] = useMemo(
    () => [
      {
        label: t(asset_risk_tasks_messages.table.asset_name_column),
        id: 'risk__risk_analysis__asset__name',
        get: ({ risk }) => risk.riskAnalysis.asset.name,
        width: '15%',
      },
      {
        label: t(asset_risk_tasks_messages.table.assigned_column),
        id: 'assigned_to__full_name',
        get: ({ assignedTo }) => <UserAvatar showName userData={assignedTo.profile} userId={assignedTo.id} />,
        width: '18%',
      },
      {
        label: t(asset_risk_tasks_messages.table.analysis_date_column),
        id: 'risk__risk_analysis__date',
        get: ({ risk }) => format(risk.riskAnalysis.date, DATE_FORMAT.defaultDate),
        width: '12%',
      },
      {
        label: t(asset_risk_tasks_messages.table.risk_name_column),
        id: 'risk__name',
        get: ({ risk }) => risk.name,
        width: '17%',
      },
      {
        id: 'description',
        label: t(asset_risk_tasks_messages.table.description_column),
        get: ({ description }) => (
          <TrimHTMLText linesToShow={2}>
            <TypographyWithHTML>{description}</TypographyWithHTML>
          </TrimHTMLText>
        ),
        width: '18%',
      },
      {
        label: t(asset_risk_tasks_messages.table.due_date_column),
        id: 'due_date',
        get: ({ dueDate }) => format(dueDate, DATE_FORMAT.defaultDate),
        width: '10%',
      },
      {
        label: t(asset_risk_tasks_messages.table.status_column),
        id: 'status',
        get: ({ status }) => t(asset_risk_messages.risk_task_status[status]),
        width: '10%',
      },
    ],
    [],
  );

  return (
    <Section
      title={manage_dashboard_messages.risk_tasks_section.title}
      titleLineAddon={[
        <TextButton key='introduction' onClick={showIntroductionDialog}>
          {t(manage_dashboard_messages.risk_tasks_section.introduction_button)}
        </TextButton>,
      ]}
      // @ts-ignore
      titleWrapper={MobileGuttersContainer}
    >
      <ManagementDashboardSectionGrid>
        <CarouselWrapper noMargin noTopPadding>
          {kpiData.map(({ description, title }) => (
            <ColoredTile key={description} centered description={description} disableRipple isLoading={title === null} title={title} />
          ))}
        </CarouselWrapper>
        <div>
          <Table
            clickableRow
            data={tableData && parseAllAssetRiskTasksForFE(tableData)}
            dataMapping={dataMapping}
            filtersService={{ availableFilters, filters, setFilters }}
            fixedHeader
            onSort={onSort}
            openInNewTab
            refetching={loading && !initialLoading}
            sort={sort}
          />
          {hasNextPage && (
            // @ts-ignore
            <FooterButton loading={loading} onClick={getTableData}>
              {t(general_messages.show_more)}
            </FooterButton>
          )}
        </div>
        <MobileGuttersContainer>
          <ManagementCommentInput fieldKey='riskTasksReport' initialValue={data?.riskTasksReport.comment || ''} reportId={reportId} />
        </MobileGuttersContainer>
      </ManagementDashboardSectionGrid>
    </Section>
  );
};

export default ManagementDashboardRiskTasksSection;

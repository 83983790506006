import React, { FC, useEffect } from 'react';

import { Skeleton } from '@material-ui/lab';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import ColoredButton from 'components/ColoredButton';
import FormTextInput from 'components/FormTextInput';
import managementReportsApi from 'config/api/managementReports/managementReports';
import QUERY_KEYS from 'config/api/QUERY_KEYS';
import RolesOrganization from 'config/permissions/RolesOrganization';
import usePermissions from 'hooks/usePermissions';
import general_messages from 'messages/general_messages';
import manage_dashboard_messages from 'messages/manage_dasboard_messages';
import { Id } from 'types/Id';

import useStyles from './ManagementCommentInput.styles';

type FormInput = {
  comment: string;
};

type Props = {
  initialValue: string;
  reportId?: Id;
  fieldKey: 'majorIncidentsReport' | 'risksReport' | 'riskTasksReport' | 'assetTasksReport' | ''; // '' is for customMutation
  customMutation?: (data: FormInput) => void;
};

const ManagementCommentInput: FC<Props> = ({ initialValue, fieldKey, reportId, customMutation }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [canModifyReports] = usePermissions([RolesOrganization.AddManagementReportAccess]);

  useEffect(() => {
    if (fieldKey === '' && !customMutation) {
      throw new Error('customMutation is required when fieldKey is empty');
    }
  }, []);

  const queryClient = useQueryClient();

  const mutation = useMutation(managementReportsApi.partialUpdateManagementReport(reportId!), {
    onError: () => {
      enqueueSnackbar(t(general_messages.something_went_wrong), { variant: 'error' });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries([QUERY_KEYS.GET_MANAGEMENT_REPORT, reportId]);
      enqueueSnackbar(t(general_messages.data_saved), { variant: 'success' });
    },
  });

  const onSubmit = ({ comment }: FormInput) => {
    if (customMutation) customMutation({ comment });
    else mutation.mutate({ [fieldKey]: { comment } });
  };

  const formik = useFormik<FormInput>({
    initialValues: { comment: initialValue },
    enableReinitialize: true,
    onSubmit,
  });
  const styles = useStyles();

  if (!reportId) {
    return <Skeleton classes={{ root: styles.skeleton }} height={151} />;
  }

  return (
    <form className={styles.container} onSubmit={formik.handleSubmit}>
      <FormTextInput
        disabled={mutation.isLoading || !canModifyReports}
        formik={formik}
        id='comment'
        label={t(manage_dashboard_messages.comment_label)}
        maxRows={12}
        multiline
        rows={3}
      />
      {/* @ts-ignore */}
      <ColoredButton customColor='secondary' disabled={mutation.isLoading || !formik.dirty} type='sumbit'>
        {t(general_messages.save)}
      </ColoredButton>
    </form>
  );
};

export default ManagementCommentInput;

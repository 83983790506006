import axios from 'axios';

import createUrlWithParams from 'helpers/createUrlWithParams';

import METHOD from '../_methods';
import { BasePaginatedQueryParams } from '../_types/BasePaginatedQueryParams';

import {
  parseSingleEmergencyRequestReportForFE,
  parseAllEmergencyRequestReportForFE,
  parseDataToCreate,
} from './emergencyRequestReport.parsers';
import type EmergencyRequestReportsApi from './emergencyRequestReport.types';

const endpoint = 'api/v1/emergency_request_reports/';

export const emergencyRequestReportApiLegacy = {
  getAllEmergencyRequestReports: (params: BasePaginatedQueryParams) => ({
    url: createUrlWithParams(endpoint, params),
    method: METHOD.GET,
  }),
};

const emergencyRequestReportApi: EmergencyRequestReportsApi = {
  getAllEmergencyRequestReports: async () => {
    const { data } = await axios.get(endpoint);
    return parseAllEmergencyRequestReportForFE(data);
  },
  getSingleEmergencyRequestReport: async id => {
    const { data } = await axios.get(`${endpoint}${id}/`);
    return parseSingleEmergencyRequestReportForFE(data);
  },
  createEmergencyRequestReport: async formData => {
    const { data } = await axios.post(endpoint, parseDataToCreate(formData));
    return parseSingleEmergencyRequestReportForFE(data);
  },
};

export default emergencyRequestReportApi;

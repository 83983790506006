import React, { FC } from 'react';

import { Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import MobileGuttersContainer from 'components/MobileGuttersContainer';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import { ManagementReportDetail } from 'config/api/managementReports/managementReports.types';
import DATE_FORMAT from 'config/constants/DATE_FORMAT';
import manage_dashboard_messages from 'messages/manage_dasboard_messages';

import useStyles from './ManagementDashboardSelectedInfo.styles';

type Props = {
  reportData?: ManagementReportDetail;
  showNonSelectedInfo?: boolean;
};

const ManagementDashboardSelectedInfo: FC<Props> = ({ reportData, showNonSelectedInfo }) => {
  const { t } = useTranslation();

  const styles = useStyles();

  if (showNonSelectedInfo)
    return (
      <Typography align='center' className={styles.noSelected} variant='body2'>
        {t(manage_dashboard_messages.selected_report.no_selected_report)}
      </Typography>
    );

  return (
    <MobileGuttersContainer className={styles.stickyContainer}>
      <div className={styles.container}>
        <div className={styles.element}>
          <Typography variant='h5'>{t(manage_dashboard_messages.selected_report.report_period)}: </Typography>
          {reportData ? (
            <Typography>
              {t(manage_dashboard_messages.from_to_date, {
                from: format(reportData.dateFrom, DATE_FORMAT.defaultDate),
                to: format(reportData.dateTo, DATE_FORMAT.defaultDate),
              })}
            </Typography>
          ) : (
            <Skeleton variant='text' width={226} />
          )}
        </div>

        <div className={styles.element}>
          <Typography variant='h5'>{t(manage_dashboard_messages.selected_report.created_at)}: </Typography>
          {reportData ? (
            <Typography>{format(reportData.createdAt, DATE_FORMAT.defaultDateWithTime)}</Typography>
          ) : (
            <Skeleton variant='text' width={127} />
          )}
        </div>

        <div className={styles.element}>
          <Typography variant='h5'>{t(manage_dashboard_messages.selected_report.updated_at)}: </Typography>
          {reportData ? (
            <Typography>{format(reportData.updatedAt, DATE_FORMAT.defaultDateWithTime)}</Typography>
          ) : (
            <Skeleton variant='text' width={127} />
          )}
        </div>

        <div className={styles.element}>
          <Typography variant='h5'>{t(manage_dashboard_messages.selected_report.created_by)}: </Typography>
          {reportData ? (
            <UserAvatar size='s' userData={reportData.createdBy.profile} userId={reportData.createdBy.id} />
          ) : (
            <Skeleton height={40} variant='circle' width={40} />
          )}
        </div>
      </div>
    </MobileGuttersContainer>
  );
};

export default ManagementDashboardSelectedInfo;

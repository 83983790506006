const asset_risk_tasks_messages = {
  dashboard: {
    title: ['asset_risk_tasks>>dashboard>>title', 'Organization asset risk tasks'],
    description: [
      'asset_risk_tasks>>dashboard>>introduction_body',
      'Organization asset risk tasks are <b>tasks</b> that are related to the risks of assets in your organization. These tasks are created by the system based on the risks of assets in your organization. You can view the tasks, mark them as started or finished, and add comments to them.',
    ],
  },
  table: {
    asset_name_column: ['asset_risk_tasks>>table>>asset_name_column', 'Name of asset'],
    assigned_column: ['asset_risk_tasks>>table>>assigned_column', 'Assigned user'],
    analysis_date_column: ['asset_risk_tasks>>table>>analysis_date_column', 'Risk analysis date'],
    asset_importance_column: ['asset_risk_tasks>>table>>asset_importance_column', 'Asset importance'],
    risk_name_column: ['asset_risk_tasks>>table>>risk_name_column', 'Risk name'],
    description_column: ['asset_risk_tasks>>table>>description_column', 'Task description'],
    due_date_column: ['asset_risk_tasks>>table>>due_date_column', 'Due date'],
    status_column: ['asset_risk_tasks>>table>>status_column', 'Status'],
  },
  stats: {
    // don't change - used in API
    notStartedTasks: ['asset_risk_tasks>>stats>>not_started_tasks', 'Not started'],
    startedTasks: ['asset_risk_tasks>>stats>>started_tasks', 'Started'],
    finishedTasks: ['asset_risk_tasks>>stats>>finished_tasks', 'Finished'],
    overdueTasks: ['asset_risk_tasks>>stats>>overdue_tasks', 'Overdue'],
  },
};

export default asset_risk_tasks_messages;

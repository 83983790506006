import React, { FC } from 'react';

import useStyles from './ManagementDashboardKPIGrid.styles';

type Props = {
  children: React.ReactNode | React.ReactNode[];
};

const ManagementDashboardKPIGrid: FC<Props> = ({ children }) => {
  const styles = useStyles();

  return <div className={styles.container}>{children}</div>;
};

export default ManagementDashboardKPIGrid;

import { makeStyles } from '@material-ui/core';

type StylesTypes = {
  noMargin: boolean;
  noTopPadding: boolean;
  leftMargin?: number;
  hideScrollbar?: boolean;
};

export default makeStyles(theme => ({
  externalWrapper: ({ noMargin, hideScrollbar }: StylesTypes) => ({
    width: '100%',
    overflowX: 'auto',
    marginTop: theme.spacing(noMargin ? 0 : 4),

    ...(hideScrollbar
      ? {
          '-ms-overflow-style': 'none' /* Internet Explorer 10+ */,
          scrollbarWidth: 'none' /* Firefox */,
          '&::-webkit-scrollbar': {
            display: 'none' /* Safari and Chrome */,
          },
        }
      : {}),
  }),
  internalWrapper: {
    display: 'flex',
    padding: ({ noTopPadding }: StylesTypes) => (noTopPadding ? theme.spacing(0, 0, 3, 0) : theme.spacing(3, 0)),
    marginLeft: ({ leftMargin }: StylesTypes) => (leftMargin ? theme.spacing(leftMargin) : 'none'),

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 2),
    },
  },
  scrollableElement: {
    display: 'flex',
    alignItems: 'center',

    '& > *': {
      flexShrink: 0,
      marginRight: theme.spacing(5),
    },
  },
}));

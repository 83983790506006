import React, { useMemo } from 'react';

import { Container, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Jumbotron from 'components/Jumbotron';
import MobileGuttersContainer from 'components/MobileGuttersContainer';
import Section from 'components/Section';
import TextButton from 'components/TextButton/TextButton';
import { ManagementReport } from 'config/api/managementReports/managementReports.types';
import useQueryParams from 'hooks/useQueryParams';
import manage_dashboard_messages from 'messages/manage_dasboard_messages';
import { useHelpDialogContext } from 'reactContext/HelpDialogContext/HelpDialogContext';

import ManagementDashboardHeading from './_components/ManagementDashboardHeading/ManagementDashboardHeading';
import ManagementDashboardIncidentsSection from './_components/ManagementDashboardIncidentsSection/ManagementDashboardIncidentsSection';
import ManagementDashboardPlannedActivitiesSection from './_components/ManagementDashboardPlannedActivitiesSection/ManagementDashboardPlannedActivitiesSection';
import ManagementDashboardRisksSection from './_components/ManagementDashboardRisksSection/ManagementDashboardRisksSection';
import ManagementDashboardRiskTasksSection from './_components/ManagementDashboardRiskTasksSection/ManagementDashboardRiskTasksSection';
import ManagementDashboardSecuritySection from './_components/ManagementDashboardSecuritySection/ManagementDashboardSecuritySection';
import ManagementDashboardSelectedInfo from './_components/ManagementDashboardSelectedInfo/ManagementDashboardSelectedInfo';
import useGetManagementReportBaseData from './_hooks/useGetManagementReportBaseData';

const ManagementDashboardPage: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up('md'));
  const { showHelpDialog } = useHelpDialogContext();

  const { queryParams, addParam, deleteParam } = useQueryParams<'selected'>();

  const onSelect = (data?: ManagementReport) => {
    if (data) {
      addParam('selected', data.id.toString());
      return;
    }
    deleteParam('selected');
  };
  const selectedId = useMemo(() => {
    if (!queryParams.selected) return undefined;
    return Number(Array.isArray(queryParams.selected) ? queryParams.selected[0] : queryParams.selected);
  }, [queryParams.selected]);

  const { data: selectedDetails } = useGetManagementReportBaseData(selectedId);

  const showIntroductionDialog = () => {
    showHelpDialog({
      title: t(manage_dashboard_messages.heading_section.introduction_title),
      body: t(manage_dashboard_messages.heading_section.introduction_body),
    });
  };

  return (
    <Container disableGutters={!upMd}>
      <Jumbotron>
        <Typography component='h1' variant='h1'>
          {t(manage_dashboard_messages.page_title)}
        </Typography>
      </Jumbotron>
      <MobileGuttersContainer>
        <Section
          noMargin
          smallPadding
          title={manage_dashboard_messages.selected_report.title}
          titleLineAddon={[
            <TextButton key='introduction' onClick={showIntroductionDialog}>
              {t(manage_dashboard_messages.heading_section.introduction_button)}
            </TextButton>,
          ]}
          titleVariant='h3'
        />
      </MobileGuttersContainer>
      <ManagementDashboardSelectedInfo reportData={selectedDetails} showNonSelectedInfo={!selectedId} />
      <MobileGuttersContainer>
        <ManagementDashboardHeading onSelect={onSelect} selectedReportId={selectedId} />
      </MobileGuttersContainer>
      {selectedId ? (
        <>
          <MobileGuttersContainer>
            <ManagementDashboardSecuritySection reportId={selectedId} />
          </MobileGuttersContainer>
          <ManagementDashboardIncidentsSection reportId={selectedId} />
          <ManagementDashboardRisksSection reportId={selectedId} />
          <ManagementDashboardRiskTasksSection reportId={selectedId} />
          <MobileGuttersContainer>
            <ManagementDashboardPlannedActivitiesSection reportId={selectedId} />
          </MobileGuttersContainer>
        </>
      ) : null}
    </Container>
  );
};

export default ManagementDashboardPage;

import React from 'react';

import { Container, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import CarouselWrapper from 'components/CarouselWrapper/CarouselWrapper';
import Jumbotron from 'components/Jumbotron';
import MobileGuttersContainer from 'components/MobileGuttersContainer';
import Section from 'components/Section';
import TypographyWithHTML from 'components/TypographyWithHTML/TypographyWithHTML';
import asset_risk_messages from 'messages/asset_risk_messages';

import RiskAnalysisDashboardKPI from './_components/RiskAnalysisDashboardKPI/RiskAnalysisDashboardKPI';
import RiskAnalysisDashboardTable from './_components/RiskAnalysisDashboardTable/RiskAnalysisDashboardTable';

type Props = {};

const RiskAnalysisDashboardPage: React.FC<Props> = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Container disableGutters={!upMd}>
      <Jumbotron>
        <Typography component='h1' variant='h1'>
          {t(asset_risk_messages.dashboard.title)}
        </Typography>
      </Jumbotron>
      <Section gutters narrow>
        <TypographyWithHTML>{t(asset_risk_messages.dashboard.description)}</TypographyWithHTML>
      </Section>
      <CarouselWrapper noTopPadding>
        <RiskAnalysisDashboardKPI />
      </CarouselWrapper>
      <MobileGuttersContainer>
        <RiskAnalysisDashboardTable />
      </MobileGuttersContainer>
    </Container>
  );
};

export default RiskAnalysisDashboardPage;

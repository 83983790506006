import React, { FC, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';

import { IconButton } from '@material-ui/core';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import PubSub from 'pubsub-js';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { ReactComponent as MoreIcon } from 'assets/icons/more.svg';
import ManagementReportDialog from 'components/_dialogs/ManagementReportDialog/ManagementReportDialog';
import ColoredButton from 'components/ColoredButton';
import ContextMenu from 'components/ContextMenu/ContextMenu';
import FooterButton from 'components/FooterButton';
import Section from 'components/Section';
import Table from 'components/Table';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import managementReportsApi, { legacyManagementReportsApi } from 'config/api/managementReports/managementReports';
import { parseAllManagementReportsForFE } from 'config/api/managementReports/managementReports.parsers';
import { ManagementReport } from 'config/api/managementReports/managementReports.types';
import DATE_FORMAT from 'config/constants/DATE_FORMAT';
import pubsub from 'config/events/pubsub';
import RolesOrganization from 'config/permissions/RolesOrganization';
import usePagination from 'hooks/usePagination';
import usePermissions from 'hooks/usePermissions';
import useSubscription from 'hooks/useSubscription';
import general_messages from 'messages/general_messages';
import manage_dashboard_messages from 'messages/manage_dasboard_messages';
import { useConfirmationModalContext } from 'reactContext/ConfirmationModalContext/ConfirmationModalContext';
import { Id } from 'types/Id';
import type { TableDataMappingRow } from 'types/Table';

type Props = {
  onSelect: (data?: ManagementReport) => void;
  selectedReportId?: Id;
};

const ManagementDashboardHeading: FC<Props> = ({ onSelect, selectedReportId }) => {
  const { t } = useTranslation();
  const [canModifyReports] = usePermissions([RolesOrganization.AddManagementReportAccess]);
  const { enqueueSnackbar } = useSnackbar();
  const [reportDialog, setReportDialog] = useState<{ isOpen: boolean; reportId: null | Id }>({ isOpen: false, reportId: null });
  const closeReportDialog = () => setReportDialog({ isOpen: false, reportId: null });

  // @ts-ignore
  const { showConfirmationModal } = useConfirmationModalContext();

  const removeMutation = useMutation(managementReportsApi.removeManagementReport, {
    onSuccess: (res, removedId) => {
      if (removedId === selectedReportId) onSelect();

      PubSub.publish(pubsub.MANAGEMENT_REPORTS_UPDATED);
      enqueueSnackbar(t(general_messages.data_removed), { variant: 'success' });
    },
  });

  const onEdit = (id: Id) => {
    setReportDialog({ isOpen: true, reportId: id });
  };

  const onDelete = async (id: Id) => {
    const confirmed = await showConfirmationModal({
      title: t(general_messages.are_you_sure),
      body: t(general_messages.operation_irreversible),
    });
    if (confirmed) removeMutation.mutate(id);
  };

  const onAdd = () => {
    setReportDialog({ isOpen: true, reportId: null });
  };

  const [sort, setSort] = useState<{ key: string | null; direction: string | null }>({ key: null, direction: null });
  const onSort = (key: string, direction: string) => setSort({ key, direction });
  const {
    data: tableData,
    getData: getTableData,
    hasNextPage,
    loading,
    refreshData,
  } = usePagination({
    endpointFunc: legacyManagementReportsApi.getAllManagementReports,
    limits: [5, 5, 5],
    isGet: true,
    sort,
  });
  useSubscription(pubsub.MANAGEMENT_REPORTS_UPDATED, refreshData);

  const parsedTableData = useMemo(() => tableData && parseAllManagementReportsForFE(tableData), [tableData]);

  useEffect(() => {
    if (parsedTableData && !selectedReportId) onSelect(parsedTableData[0]);
  }, [parsedTableData]);

  const renderContextMenu = useCallback(
    (data: ManagementReport) => {
      const elements = [
        { label: t(general_messages.edit), onClick: () => onEdit(data.id) },
        { label: t(general_messages.delete), onClick: () => onDelete(data.id) },
      ];

      if (!elements.length) return null;
      return (
        <ContextMenu elements={elements} id={`assets-table-row-${data.id}`}>
          <IconButton>
            <MoreIcon />
          </IconButton>
        </ContextMenu>
      );
    },
    [tableData],
  );

  const dataMapping = useMemo(() => {
    const result: TableDataMappingRow<ManagementReport>[] = [
      {
        id: 'date_from',
        label: t(manage_dashboard_messages.heading_section.report_period_table_column),
        get: ({ dateFrom, dateTo }) =>
          t(manage_dashboard_messages.from_to_date, {
            from: format(dateFrom, DATE_FORMAT.defaultDate),
            to: format(dateTo, DATE_FORMAT.defaultDate),
          }),
        width: '25%',
      },
      {
        id: 'created_by',
        label: t(manage_dashboard_messages.heading_section.created_by_table_column),
        get: ({ createdBy }) => <UserAvatar lazy showName userData={createdBy.profile} userId={createdBy.id} />,
        width: canModifyReports ? '39%' : '44%',
      },
      {
        id: 'created_at',
        label: t(manage_dashboard_messages.heading_section.created_at_table_column),
        get: ({ createdAt }) => format(createdAt, DATE_FORMAT.defaultDate),
        width: '15%',
      },
      {
        id: 'updated_at',
        label: t(manage_dashboard_messages.heading_section.updated_at_table_column),
        get: ({ updatedAt }) => format(updatedAt, DATE_FORMAT.defaultDate),
        width: '15%',
      },
    ];
    if (canModifyReports) {
      result.push({
        id: 'context',
        width: '6%',
        label: '',
        isAddon: true,
        get: (data): React.ReactNode => renderContextMenu(data) || ' ',
        blockSorting: true,
      });
    }

    return result;
  }, [t]);

  const isRowSelected = (data: ManagementReport) => !!selectedReportId && data.id === selectedReportId;
  const onRowClick = (e: SyntheticEvent, data: ManagementReport) => {
    onSelect(data);
  };

  return (
    <>
      <Section
        paddingTop={4}
        smallPadding
        title={manage_dashboard_messages.heading_section.table_title}
        titleLineAddon={[
          // @ts-ignore
          <ColoredButton key='introduction' customColor='secondary' disabled={!canModifyReports} onClick={onAdd}>
            {t(manage_dashboard_messages.heading_section.add_report)}
          </ColoredButton>,
        ]}
        titleVariant='h3'
      >
        <Table
          data={parsedTableData}
          dataMapping={dataMapping}
          fixedHeader
          isRowSelected={isRowSelected}
          onRowClick={onRowClick}
          onSort={onSort}
          refetching={loading}
          sort={sort}
        />
        {hasNextPage && (
          // @ts-ignore
          <FooterButton loading={loading} onClick={getTableData}>
            {t(general_messages.show_more)}
          </FooterButton>
        )}
      </Section>
      {reportDialog.isOpen && (
        <ManagementReportDialog
          onClose={closeReportDialog}
          open={reportDialog.isOpen}
          reportId={reportDialog.reportId}
          shouldRefreshView={reportDialog.reportId === selectedReportId}
        />
      )}
    </>
  );
};

export default ManagementDashboardHeading;

import React, { useCallback, useMemo, useState } from 'react';

import { IconButton } from '@material-ui/core';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import PubSub from 'pubsub-js';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';

import { ReactComponent as MoreIcon } from 'assets/icons/more.svg';
import AssetRiskTaskDialog from 'components/_dialogs/AssetRiskTaskDialog/AssetRiskTaskDialog';
import ContextMenu from 'components/ContextMenu/ContextMenu';
import FooterButton from 'components/FooterButton';
import Section from 'components/Section';
import Table from 'components/Table';
import TextButton from 'components/TextButton/TextButton';
import TrimHTMLText from 'components/TrimHTMLText/TrimHTMLText';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import activeUserRiskAnalysesApi from 'config/api/activeUserRiskAnalyses/activeUserRiskAnalyses';
import assetRiskTasksApi, { legacyAssetRiskTasksApi } from 'config/api/assetRiskTasks/assetRiskTasks';
import { parseAllAssetRiskTasksForFE } from 'config/api/assetRiskTasks/assetRiskTasks.parsers';
import { AssetRiskTasks } from 'config/api/assetRiskTasks/assetRiskTasks.types';
import QUERY_KEYS from 'config/api/QUERY_KEYS';
import DATE_FORMAT from 'config/constants/DATE_FORMAT';
import DIRECTIONS from 'config/constants/sortDirections';
import pubsub from 'config/events/pubsub';
import usePagination from 'hooks/usePagination';
import usePrepareAvailableFilters from 'hooks/usePrepareAvailableFilters/usePrepareAvailableFilters';
import useSubscription from 'hooks/useSubscription';
import asset_risk_messages from 'messages/asset_risk_messages';
import general_messages from 'messages/general_messages';
import { useConfirmationModalContext } from 'reactContext/ConfirmationModalContext/ConfirmationModalContext';
import { useHelpDialogContext } from 'reactContext/HelpDialogContext/HelpDialogContext';
import assetTaskStatusFilter from 'services/_filters/parsers/_assetTasksFilters/assetTaskStatusFilter';
import isOverdueTaskFilter from 'services/_filters/parsers/_assetTasksFilters/isOverdueTaskFilter';
import riskFilterFactory from 'services/_filters/parsers/_riskAnalysisFilters/riskFilter';
import riskQuadrantFilter from 'services/_filters/parsers/_riskAnalysisFilters/riskQuadrantFilterFactory';
import parseFiltersForRequest from 'services/parseFiltersForRequest';
import { Id } from 'types/Id';
import type { TableDataMappingRow } from 'types/Table';

import RiskQuadrantCell from '../RiskQuadrantCell/RiskQuadrantCell';

type Props = {
  riskAnalysisId: Id;
};

const RiskAnalysisTasksTable: React.FC<Props> = ({ riskAnalysisId }) => {
  const { t } = useTranslation();
  const { showHelpDialog } = useHelpDialogContext();
  const { showConfirmationModal } = useConfirmationModalContext();
  const { enqueueSnackbar } = useSnackbar();

  const showRiskAnalysisTasksTableIntroductionDialog = () => {
    showHelpDialog({
      title: t(asset_risk_messages.risk_task_table_introduction.title),
      body: t(asset_risk_messages.risk_task_table_introduction.body),
    });
  };

  const { data: baseRiskData } = useQuery({
    queryKey: [QUERY_KEYS.GET_SINGLE_RISK_ANALYSIS, riskAnalysisId],
    queryFn: () => activeUserRiskAnalysesApi.getSingleActiveUserRiskAnalysis(riskAnalysisId),
  });

  const [sort, setSort] = useState<{ key: string | null; direction: string | null }>({ key: 'due_date', direction: DIRECTIONS.DESC });
  const onSort = (key: string, direction: string) => setSort({ key, direction });

  const availableFilters = usePrepareAvailableFilters({
    is_overdue: isOverdueTaskFilter,
    status: assetTaskStatusFilter,
    risk__risk_quadrant: riskQuadrantFilter(asset_risk_messages.risk_table.calculated_risk_filter),
    risk: riskFilterFactory({ risk_analysis: riskAnalysisId }),
  });
  const [filters, setFilters] = useState({});

  const {
    data: tableData,
    getData: getTableData,
    hasNextPage,
    loading,
    refreshData,
  } = usePagination({
    endpointFunc: legacyAssetRiskTasksApi.getAllAssetRiskTasks('general'),
    limits: [15, 10, 5],
    isGet: true,
    dependencies: [filters],
    sort,
    filters: {
      risk__risk_analysis: riskAnalysisId,
      ...parseFiltersForRequest(filters, {}, availableFilters || {}),
    },
  });
  useSubscription(pubsub.RISK_ANALYSIS_TASK_UPDATED, refreshData);

  const [taskDialog, setTaskDialog] = useState<{ id: null | Id; initiallyEdit: boolean }>({ id: null, initiallyEdit: false });
  const isTaskDialogOpen = useMemo(() => Boolean(taskDialog.id), [taskDialog]);

  const onEdit = (id: Id) => {
    setTaskDialog({ id, initiallyEdit: true });
  };

  const onRowClick = (id: Id) => {
    setTaskDialog({ id, initiallyEdit: false });
  };

  const closeTaskDialog = () => {
    setTaskDialog({ id: null, initiallyEdit: false });
  };

  const deleteMutation = useMutation(assetRiskTasksApi.removeAssetRiskTask);

  const onDelete = async (id: Id) => {
    const confirmed = await showConfirmationModal({
      title: t(general_messages.are_you_sure),
      body: t(general_messages.operation_irreversible),
    });
    if (confirmed) {
      await deleteMutation.mutateAsync(id);
      enqueueSnackbar(t(general_messages.data_removed), { variant: 'success' });
      PubSub.publish(pubsub.RISK_ANALYSIS_RISKS_UPDATED);
      PubSub.publish(pubsub.RISK_ANALYSIS_TASK_UPDATED);
    }
  };

  const renderContextMenu = useCallback(
    (data: AssetRiskTasks) => {
      const elements = [
        { label: t(general_messages.edit), onClick: () => onEdit(data.id) },
        { label: t(general_messages.delete), onClick: () => onDelete(data.id), disabled: baseRiskData?.status === 'completed' },
      ];

      return (
        <ContextMenu elements={elements} id={`assetRisk-${data.id}`}>
          <IconButton>
            <MoreIcon />
          </IconButton>
        </ContextMenu>
      );
    },
    [tableData, baseRiskData],
  );

  const dataMapping = useMemo(() => {
    const result: TableDataMappingRow<AssetRiskTasks>[] = [
      {
        id: 'risk__name',
        label: t(asset_risk_messages.risk_task_table.risk_name_column),
        get: ({ risk }) => risk.name,
        width: '15%',
      },
      {
        id: 'risk__risk_quadrant',
        label: t(asset_risk_messages.risk_task_table.risk_quadrant_column),
        get: ({ risk }) => (
          <RiskQuadrantCell consequence={risk.consequence} probability={risk.probability} riskQuadrant={risk.riskQuadrant} />
        ),
        width: '16%',
      },
      {
        id: 'planned_action',
        label: t(asset_risk_messages.risk_task_table.planned_action_column),
        get: ({ plannedAction }) => <TrimHTMLText linesToShow={2}>{plannedAction}</TrimHTMLText>,
        width: '18%',
      },
      {
        id: 'assigned_to',
        label: t(asset_risk_messages.risk_task_table.assigned_to_column),
        get: ({ assignedTo }) => <UserAvatar showName userData={assignedTo.profile} userId={assignedTo.id} />,
        width: '19%',
      },
      {
        id: 'due_date',
        label: t(asset_risk_messages.risk_task_table.due_date_column),
        get: ({ dueDate }) => format(dueDate, DATE_FORMAT.defaultDate),
        width: '8%',
      },
      {
        id: 'status',
        label: t(asset_risk_messages.risk_task_table.status_column),
        get: ({ status }) => t(asset_risk_messages.risk_task_status[status]),
        width: '10%',
      },
      {
        id: 'last_status_date',
        label: t(asset_risk_messages.risk_task_table.last_status_date_column),
        get: ({ lastStatusDate }) => format(lastStatusDate, DATE_FORMAT.defaultDate),
        width: '8%',
      },
      {
        label: '',
        id: 'context',
        isAddon: true,
        blockSorting: true,
        get: (data): React.ReactNode => renderContextMenu(data) || ' ',
        width: '6%',
      },
    ];

    return result;
  }, [t, tableData, baseRiskData]);

  return (
    <Section
      paddingTop={3}
      showSeparator
      smallPadding
      title={asset_risk_messages.risk_task_table.title}
      titleLineAddon={[
        <TextButton key='introduction' onClick={showRiskAnalysisTasksTableIntroductionDialog}>
          {t(asset_risk_messages.risk_task_table_introduction.button)}
        </TextButton>,
      ]}
      titleVariant='h3'
    >
      <Table
        data={tableData && parseAllAssetRiskTasksForFE(tableData)}
        dataMapping={dataMapping}
        filtersService={{ availableFilters, filters, setFilters }}
        onRowClick={(_, element) => onRowClick(element.id)}
        onSort={onSort}
        refetching={loading}
        sort={sort}
      />
      {hasNextPage && (
        // @ts-ignore
        <FooterButton loading={loading} onClick={getTableData}>
          {t(general_messages.show_more)}
        </FooterButton>
      )}
      {isTaskDialogOpen && (
        <AssetRiskTaskDialog
          disableBaseData={baseRiskData?.status === 'completed'}
          onClose={closeTaskDialog}
          open={isTaskDialogOpen}
          previewMode={!taskDialog.initiallyEdit}
          risk={null}
          taskId={taskDialog.id}
        />
      )}
    </Section>
  );
};
export default RiskAnalysisTasksTable;

import { AxiosResponse } from 'axios';

import { Id } from 'types/Id';
import { User, UserDTO } from 'types/User';

export const riskStatusInManagementReportChoices = ['low', 'medium', 'high'] as const;
export type RiskStatusInManagementReport = typeof riskStatusInManagementReportChoices[number];

export type ManagementReportDTO = {
  id: number;
  created_at: string;
  updated_at: string;
  created_by: UserDTO;
  date_from: string;
  date_to: string;
};

export type ManagementReport = {
  id: Id;
  createdAt: Date;
  updatedAt: Date;
  createdBy: User;
  dateFrom: Date;
  dateTo: Date;
};

export type ManagementReportDetailDTO = {
  id: number;
  created_at: string;
  updated_at: string;
  created_by: UserDTO;
  date_from: string;
  date_to: string;
  security_report: {
    id: number;
    document: null | {
      id: number;
      name: string;
      url: string;
    };
    comment: null | string;
    risk_status: null | RiskStatusInManagementReport;
  };
  major_incidents_report: {
    id: number;
    comment: null | string;
    total_incidents: number;
    gdpr_incidents: number;
    low_incidents: number;
    medium_incidents: number;
    high_incidents: number;
    highest_incidents: number;
    ongoing_incidents: number;
  };
  risks_report: {
    id: number;
    comment: null | string;
    assets_analyzed_last_year: number;
    assets_not_analyzed_last_year: number;
    assets_with_q1_risk: number;
    assets_with_q2_risk: number;
    assets_with_q1_risk_not_to_handle: number;
    assets_with_q2_risk_not_to_handle: number;
  };
  risk_tasks_report: {
    id: number;
    comment: null | string;
    not_started_tasks: number;
    started_tasks: number;
    overdue_tasks: number;
  };
  asset_tasks_report: null | {
    id: number;
    comment: null | string;
  };
};

export type ManagementReportDetail = {
  id: Id;
  createdAt: Date;
  updatedAt: Date;
  createdBy: User;
  dateFrom: Date;
  dateTo: Date;
  securityReport: {
    id: Id;
    document: null | {
      id: Id;
      name: string;
      url: string;
    };
    comment: string;
    riskStatus: null | RiskStatusInManagementReport;
  };
  majorIncidentsReport: {
    id: Id;
    comment: string;
    totalIncidents: number;
    gdprIncidents: number;
    lowIncidents: number;
    mediumIncidents: number;
    highIncidents: number;
    highestIncidents: number;
    ongoingIncidents: number;
  };
  risksReport: {
    id: Id;
    comment: string;
    assetsAnalyzedLastYear: number;
    assetsNotAnalyzedLastYear: number;
    assetsWithQ1Risk: number;
    assetsWithQ2Risk: number;
    assetsWithQ1RiskNotToHandle: number;
    assetsWithQ2RiskNotToHandle: number;
  };
  riskTasksReport: {
    id: Id;
    comment: string;
    notStartedTasks: number;
    startedTasks: number;
    overdueTasks: number;
  };
  assetTasksReport: null | {
    id: Id;
    comment: string;
  };
};

export type ManagementReportSecurityPartialUpdateDTO = {
  document: File | null;
  comment: string;
  risk_status: RiskStatusInManagementReport;
};

export type ManagementReportSecurityPartialUpdateForm = {
  document: File | null;
  comment: string;
  riskStatus: RiskStatusInManagementReport;
};

export type ManagementReportPartialUpdateDTO = {
  date_from: string;
  date_to: string;
  major_incidents_report: {
    comment: string;
  };
  risks_report: {
    comment: string;
  };
  risk_tasks_report: {
    comment: string;
  };
  asset_tasks_report: {
    comment: string;
  };
};

export type ManagementReportPartialUpdateForm = {
  dateFrom: Date;
  dateTo: Date;
  majorIncidentsReport: {
    comment: string;
  };
  risksReport: {
    comment: string;
  };
  riskTasksReport: {
    comment: string;
  };
  assetTasksReport: {
    comment: string;
  };
};

type ManagementReportsApi = {
  getAllManagementReports: () => Promise<ManagementReport[]>;
  getSingleManagementReport: (id: Id) => Promise<ManagementReportDetail>;
  removeManagementReport: (id: Id) => Promise<AxiosResponse>;
  createManagementReport: (formData: Pick<ManagementReportPartialUpdateForm, 'dateTo' | 'dateFrom'>) => Promise<ManagementReportDetail>;
  partialUpdateManagementReport: (id: Id) => (formData: Partial<ManagementReportPartialUpdateForm>) => Promise<ManagementReportDetail>;
  partialUpdateManagementReportSecurity: (
    id: Id,
  ) => (formData: Partial<ManagementReportSecurityPartialUpdateForm>) => Promise<ManagementReportDetail>;
};

export default ManagementReportsApi;

import axios from 'axios';

import { parseOrganizationRiskAnalysesStatsForFE } from './organizationRiskAnalysesStats.parsers';
import type ActiveUserRiskAnalysesAssetsForCreateApi from './organizationRiskAnalysesStats.types';

const endpoint = 'api/v1/organization/risk_analyses/stats/';

const organizationRiskAnalysesStatsApi: ActiveUserRiskAnalysesAssetsForCreateApi = {
  gatOrganizationRiskAnalysesStats: async () => {
    const { data } = await axios.get(endpoint);
    return parseOrganizationRiskAnalysesStatsForFE(data);
  },
};

export default organizationRiskAnalysesStatsApi;

import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  selectInput: {
    maxWidth: '480px',
  },

  fileRow: {
    display: 'flex',
    gap: theme.spacing(4),
  },

  fileName: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    // @ts-ignore
    color: theme.palette.primary[900],
  },
}));

const asset_risk_messages = {
  risk_quadrant: ['asset_risk>>risk_quadrant', 'Risk quadrant'],
  dashboard_table: {
    title: ['asset_risk>>dashboard_table>>title', 'My asset risk analysis'],
    empty_list: ['asset_risk>>dashboard_table>>empty_list', 'No asset risks found'],
    add_new_button: ['asset_risk>>dashboard_table>>empty_list', 'New risk analysis'],

    introduction_button: ['asset_risk>>dashboard_table>>button', 'Introduction'],
    introduction_title: ['asset_risk>>dashboard_table>>title', 'My asset risk analysis - how it works?'],
    introduction_body: [
      'asset_risk>>dashboard_table>>body',
      'It works perfectly - lorem ipsum, description is needed here, and <b>HTML</b> is also possible, so we need some more description here please',
    ],

    column_asset_name: ['asset_risk>>dashboard_table>>column_asset_name', 'Asset name'],
    column_analysis_date: ['asset_risk>>dashboard_table>>column_analysis_date', 'Date'],
    column_analysis_status: ['asset_risk>>dashboard_table>>column_analysis_status', 'Status'],
    column_risks_number: ['asset_risk>>dashboard_table>>column_risks_number', 'Nr. identified risk'],
    column_risks_tasks_number: ['asset_risk>>dashboard_table>>column_risks_tasks_number', 'Nr. risk tasks'],

    context_menu_edit: ['asset_risk>>dashboard_table>>context_menu_edit', 'Edit'],
    context_menu_reopen: ['asset_risk>>dashboard_table>>context_menu_reopen', 'Reopen'],
    context_menu_delete: ['asset_risk>>dashboard_table>>context_menu_delete', 'Delete'],
  },

  delete_confirmation_title: ['asset_risk>>delete_confirmation_title', 'Delete risk analysis'],
  delete_confirmation_body: [
    'asset_risk>>delete_confirmation_body',
    'Are you sure you want to delete this risk analysis? Operation cannot be undone.',
  ],
  delete_success: ['asset_risk>>delete_success', 'Risk analysis has been deleted successfully.'],

  add_new_risk_analysis: {
    dialog_title: ['asset_risk>>add_new_risk_analysis>>dialog_title', 'Create new risk analysis'],
    dialog_description: [
      'asset_risk>>add_new_risk_analysis>>dialog_description',
      'Choose the asset you want to create a new risk analysis for. You can only create analysis for assets that your own assets that do not already have an analysis in progress.',
    ],
    dialog_asset_select_label: ['asset_risk>>add_new_risk_analysis>>dialog_asset_select_label', 'Choose the asset you want to work with'],
    should_copy_risks_label: ['asset_risk>>add_new_risk_analysis>>should_copy_risks_label', 'Copy risks from previous analysis'],
    no_assets_to_analyse_info: [
      'asset_risk>>add_new_risk_analysis>>no_assets_to_analyse_info',
      'There are no assets to create a risk analysis for.',
    ],
  },

  risk_dialog: {
    add_title: ['asset_risk>>risk_dialog>>add_title', 'Add new risk'],
    edit_title: ['asset_risk>>risk_dialog>>edit_title', 'Edit risk'],

    nameLabel: ['asset_risk>>risk_dialog>>nameLabel', 'Risk name'],
    riskIdentificationWayLabel: ['asset_risk>>risk_dialog>>riskIdentificationWayLabel', 'Risk identification way'],
    primaryConsequenceLabel: ['asset_risk>>risk_dialog>>primaryConsequenceLabel', 'Primary consequence'],
    probabilityLabel: ['asset_risk>>risk_dialog>>probabilityLabel', 'Probability'],
    consequenceLabel: ['asset_risk>>risk_dialog>>consequenceLabel', 'Consequence'],
    consequencesDescriptionLabel: ['asset_risk>>risk_dialog>>consequencesDescriptionLabel', 'Consequences description'],
    descriptionLabel: ['asset_risk>>risk_dialog>>descriptionLabel', 'Description'],
    decisionLabel: ['asset_risk>>risk_dialog>>decisionLabel', 'Decision'],
    placeLabel: ['asset_risk>>risk_dialog>>placeLabel', 'Place'],
    affectIndividualsLabel: ['asset_risk>>risk_dialog>>affectIndividualsLabel', 'Affect individuals'],
  },

  status: {
    in_progress: ['asset_risk>>status>>in_progress', 'In progress'], // don't touch without changes in API - it's connected
    completed: ['asset_risk>>status>>completed', 'Completed'], // don't touch without changes in API - it's connected
  },

  details_page_context_menu: {
    edit: ['asset_risk>>details_page_context_menu>>edit', 'Edit'],
    delete: ['asset_risk>>details_page_context_menu>>delete', 'Delete'],
  },

  details_page: {
    title: ['asset_risk>>detail_view>>title', 'Asset risk analysis'],
    subtitle: ['asset_risk>>detail_view>>subtitle', 'Asset risk analysis: {{ assetName }}'],

    intro_title: ['asset_risk>>detail_view>>intro_title', 'Intro'],
    intro_content: [
      'asset_risk>>detail_view>>intro_content',
      'Short description on how to work with risk analysis in general. Al though most Indians have become aware of online <b>scams</b>, the scam industry continues to flourish in India. Recently, one of.',
    ],

    metadata_title: ['asset_risk>>detail_view>>metadata_title', 'Metadata'],
    metadata_dialog_title: ['asset_risk>>detail_view>>metadata_dialog_title', 'Edit metadata'],
    creation_date_label: ['asset_risk>>detail_view>>creation_date_label', 'Created at'],
    last_update_label: ['asset_risk>>detail_view>>last_update_label', 'Last update'],
    date_of_analysis_label: ['asset_risk>>detail_view>>date_of_analysis_label', 'Date of analysis'],
    status_label: ['asset_risk>>detail_view>>status_label', 'Status'],
    participants_label: ['asset_risk>>detail_view>>participants_label', 'Participants'],

    matrix_title: ['asset_risk>>detail_view>>matrix_title', 'Risk matrix'],
  },
  matrix: {
    consequence_label: ['asset_risk>>matrix>>consequence_level', 'Consequence if the risk occurs'],
    probability_label: ['asset_risk>>matrix>>probability_label', 'Probability of the risk occurring'],
  },
  risk_consequence_scale: {
    1: ['asset_risk>>risk_consequence_scale>>consequence_1', 'Negligible'],
    2: ['asset_risk>>risk_consequence_scale>>consequence_2', 'Mild'],
    3: ['asset_risk>>risk_consequence_scale>>consequence_3', 'Serious'],
    4: ['asset_risk>>risk_consequence_scale>>consequence_4', 'Very serious'],
  },
  risk_probability_scale: {
    1: ['asset_risk>>risk_probability_scale>>probability_1', 'Unlikely'],
    2: ['asset_risk>>risk_probability_scale>>probability_2', 'Low probability'],
    3: ['asset_risk>>risk_probability_scale>>probability_3', 'High probability'],
    4: ['asset_risk>>risk_probability_scale>>probability_4', 'Very High probability'],
  },
  risk_decision: {
    not_decided: ['asset_risk>>risk_decision>>not_decided', 'Not decided'], // don't touch without changes in API - it's connected
    accepted_risk: ['asset_risk>>risk_decision>>accepted_risk', 'Accepted risk'], // don't touch without changes in API - it's connected
    handle_risk: ['asset_risk>>risk_decision>>handle_risk', 'Handle risk'], // don't touch without changes in API - it's connected
  },
  risk_decision_filter_label: ['asset_risk>>risk_decision_filter_label', 'Risk decision'],
  risk_place: {
    external: ['asset_risk>>risk_place>>external', 'External'], // don't touch without changes in API - it's connected
    internal: ['asset_risk>>risk_place>>internal', 'Internal'], // don't touch without changes in API - it's connected
    third_party: ['asset_risk>>risk_place>>third_party', 'Third party'], // don't touch without changes in API - it's connected
  },
  risk_table: {
    title: ['asset_risk>>risk_table>>title', 'Identified risks'],
    add_button: ['asset_risk>>risk_table>>add_button', 'New risk'],

    risk_label: ['asset_risk>>risk_table>>risk_label', 'Identified Risk'],
    consequence_label: ['asset_risk>>risk_table>>consequence_label', 'Prim consequence'],
    priority_label: ['asset_risk>>risk_table>>priority_label', 'Priority'],
    decision_label: ['asset_risk>>risk_table>>decision_label', 'Decision'],
    affects_ppl_label: ['asset_risk>>risk_table>>affects_ppl_label', 'Affects ppl'],
    tasks_label: ['asset_risk>>risk_table>>tasks_label', 'Tasks'],
    place_label: ['asset_risk>>risk_table>>place_label', 'Place'],

    priority_cell_consequence_label: ['asset_risk>>risk_table>>priority_cell_consequence_label', 'Consequence'],
    priority_cell_consequence_label_short: ['asset_risk>>risk_table>>priority_cell_consequence_label_short', 'Conseq'],
    priority_cell_probability_label: ['asset_risk>>risk_table>>priority_cell_probability_label', 'Probability'],
    priority_cell_probability_label_short: ['asset_risk>>risk_table>>priority_cell_probability_label_short', 'Prob'],

    calculated_risk_filter: ['asset_risk>>risk_table>>calculated_risk_filter', 'Calculated risk quadrant'],
    highest_quadrant_risk_filter: ['asset_risk>>risk_table>>highest_quadrant_risk_filter', 'Highest risk quadrant'],
    has_unhandled_risk_filter: ['asset_risk>>risk_table>>has_unhandled_risk_filter', 'Has unhandled risks'],
    affects_ppl_filter: ['asset_risk>>risk_table>>affects_ppl_filter', 'Will affect individuals'],
    place_filter: ['asset_risk>>risk_table>>place_filter', 'Place of risk'],
    asset_importance_filter: ['asset_risk>>risk_table>>asset_importance_filter', 'Importance of asset which risk is linked to'],

    context_add_task: ['asset_risk>>risk_table>>context_add_task', 'Add task for this risk'],
  },
  risk_task_dialog: {
    asset_name_label: ['asset_risk>>risk_task_dialog>>asset_name_label', 'Asset name'],
    asset_type_label: ['asset_risk>>risk_task_dialog>>asset_type_label', 'Asset type'],
    asset_owner_label: ['asset_risk>>risk_task_dialog>>asset_owner_label', 'Asset owner'],
    analysis_date_label: ['asset_risk>>risk_task_dialog>>analysis_date_label', 'Analysis date'],
    risk_description_label: ['asset_risk>>risk_task_dialog>>risk_description_label', 'Risk description'],
    create_title: ['asset_risk>>risk_task_dialog>>create_title', 'Add risk task'],
    edit_title: ['asset_risk>>risk_task_dialog>>edit_title', 'Edit risk task'],
    preview_title: ['asset_risk>>risk_task_dialog>>preview_title', 'Risk task'],
    comment_label: ['asset_risk>>risk_task_dialog>>comment_label', 'Comment'],
    assigned_to_label: ['asset_risk>>risk_task_dialog>>assigned_to_label', 'Assigned to'],
    due_date_label: ['asset_risk>>risk_task_dialog>>due_date_label', 'Due date'],
    planned_action_label: ['asset_risk>>risk_task_dialog>>planned_action_label', 'Planned action'],
    status_label: ['asset_risk>>risk_task_dialog>>status_label', 'Status'],
    description_label: ['asset_risk>>risk_task_dialog>>description_label', 'Description'],
  },
  risk_table_introduction: {
    button: ['asset_risk>>risk_table_introduction>>button', 'Introduction'],
    title: ['asset_risk>>risk_table_introduction>>title', 'Identified risks - how it works?'],
    body: [
      'asset_risk>>risk_table_introduction>>body',
      'Short description on how to work with risks. Al though most Indians have become aware of online <b>scams</b>, the scam industry continues to flourish in India. Recently, one of.',
    ],
  },

  asset_reports_table: {
    title: ['asset_risk>>asset_reports_table>>title', 'Incidents linked to Asset'],
    introduction_title: ['asset_risk>>asset_reports_table>>introduction_title', 'Incidents linked to Asset - how it works?'],
    introduction_body: [
      'asset_risk>>asset_reports_table>>introduction_body',
      'Short description about this table. Al though most Indians have become aware of online <b>scams</b>, the scam industry continues to flourish in India. Recently, one of.',
    ],
    introduction_button: ['asset_risk>>asset_reports_table>>introduction_button', 'Introduction'],

    name_column: ['asset_risk>>asset_reports_table>>name_column', 'Name'],
    is_gdpr_column: ['asset_risk>>asset_reports_table>>is_gdpr_column', 'Is GDPR'],
    is_ongoing_column: ['asset_risk>>asset_reports_table>>is_ongoing_column', 'Is Ongoing'],
    priority_column: ['asset_risk>>asset_reports_table>>priority_column', 'Priority'],
    phase_column: ['asset_risk>>asset_reports_table>>phase_column', 'Phase'],
    category_column: ['asset_risk>>asset_reports_table>>category_column', 'Category'],
    incident_date_column: ['asset_risk>>asset_reports_table>>incident_date_column', 'Incident date'],
  },
  risk_task_table: {
    title: ['asset_risk>>risk_task_table>>title', 'Asset Risks Tasks'],
    add_button: ['asset_risk>>risk_task_table>>add_button', 'New risk'],

    risk_name_column: ['asset_risk>>risk_task_table>>risk_name_column', 'Risk'],
    risk_quadrant_column: ['asset_risk>>risk_task_table>>risk_quadrant_column', 'Risk quadrant'],
    planned_action_column: ['asset_risk>>risk_task_table>>planned_action_column', 'Planned action'],
    assigned_to_column: ['asset_risk>>risk_task_table>>assigned_to_column', 'Assigned to'],
    due_date_column: ['asset_risk>>risk_task_table>>due_date_column', 'Due date'],
    status_column: ['asset_risk>>risk_task_table>>status_column', 'Status'],
    last_status_date_column: ['asset_risk>>risk_task_table>>last_status_date_column', 'Last status date'],

    identified_risk_filter_label: ['asset_risk>>risk_task_table>>identified_risk_filter_label', 'Identified risk'],
  },
  personal_dashboard_risk_task_table: {
    title: ['asset_risk>>personal_dashboard_risk_task_table>>title', 'My Asset Risks Tasks'],

    planned_action_column: ['asset_risk>>personal_dashboard_risk_task_table>>planned_action_column', 'Planned action'],
    asset_name_column: ['asset_risk>>personal_dashboard_risk_task_table>>asset_name_column', 'Asset Name'],
    due_date_column: ['asset_risk>>personal_dashboard_risk_task_table>>due_date_column', 'Due date'],
    status_column: ['asset_risk>>personal_dashboard_risk_task_table>>status_column', 'Status'],
  },
  risk_task_status: {
    finished: ['asset_risk>>risk_task_status>>finished', 'Finished'],
    not_started: ['asset_risk>>risk_task_status>>not_started', 'Not started'],
    started: ['asset_risk>>risk_task_status>>started', 'Started'],
  },
  risk_task_table_introduction: {
    button: ['asset_risk>>risk_task_table_introduction>>button', 'Introduction'],
    title: ['asset_risk>>risk_task_table_introduction>>title', 'Asset Risk Tasks - how it works?'],
    body: [
      'asset_risk>>risk_task_table_introduction>>body',
      'Short description on how to work with asset risk tasks. Al though most Indians have become aware of online <b>scams</b>, the scam industry continues to flourish in India. Recently, one of.',
    ],
  },
  personal_dashboard_risk_task_table_introduction: {
    button: ['asset_risk>>personal_dashboard_risk_task_table_introduction>>button', 'Introduction'],
    title: ['asset_risk>>personal_dashboard_risk_task_table_introduction>>title', 'My Asset Risk Tasks - how it works?'],
    body: [
      'asset_risk>>personal_dashboard_risk_task_table_introduction>>body',
      'Short description on how to work with asset risk tasks. Al though most Indians have become aware of online <b>scams</b>, the scam industry continues to flourish in India. Recently, one of.',
    ],
  },
  disabled_info: {
    adding_new_risk: ['asset_risk>>disabled_info>>adding_new_risk', 'You cannot add new risk to completed analysis.'],
    editing_risk: ['asset_risk>>disabled_info>>editing_risk', 'You cannot edit completed analysis.'],
    status_changing_info: [
      'asset_risk>>disabled_info>>status_changing_info',
      `Status changes are only allowed in following cases: 
<ul>
<li>In progress - can be set only for completed analysis and for the latest analysis for particular asset.</li>
<li>Completed - can be set only for analysis in progress, with all risk handled or accepted and with at least one task assigned to <b>every handled</b> risk</li>
</ul>`,
    ],
    delete_info: ['asset_risk>>disabled_info>>delete_info', 'You cannot delete analysis with assigned risks.'],
    metadata_info: ['asset_risk>>disabled_info>>metadata_info', 'You cannot edit completed risk analysis.'],
  },
  dashboard: {
    title: ['asset_risk>>dashboard>>title', 'Risk analysis dashboard'],
    description: [
      'asset_risk>>dashboard>>description',
      'Welcome to the risk analysis dashboard. Here you can see all your <b>risk analysis</b> in one place. You can create new risk analysis, edit or delete existing ones. You can also see all the risks identified for each asset and manage them. You can also see all the tasks linked to each risk.',
    ],
  },
  analysis_table: {
    asset_name_column: ['asset_risk>>analysis_table>>asset_name_column', 'Asset name'],
    asset_type_column: ['asset_risk>>analysis_table>>asset_type_column', 'Asset type'],
    asset_owner_column: ['asset_risk>>analysis_table>>asset_owner_column', 'Asset owner'],
    last_analysis_column: ['asset_risk>>analysis_table>>last_analysis_column', 'Last analysis'],
    status_column: ['asset_risk>>analysis_table>>status_column', 'Status'],
    importance_column: ['asset_risk>>analysis_table>>importance_column', 'Asset importance'],
    gdpr_column: ['asset_risk>>analysis_table>>gdpr_column', 'GDPR info'],
    highest_risk_quadrant_column: ['asset_risk>>analysis_table>>highest_risk_quadrant_column', 'Highest risk\u00A0q.'],
    unhandled_risk_column: ['asset_risk>>analysis_table>>unhandled_risk_column', 'Unhandled risks'],
    only_owner_can_view_analysis: ['asset_risk>>analysis_table>>only_owner_can_view_analysis', 'Only asset owner can view analysis'],
  },
};

export default asset_risk_messages;

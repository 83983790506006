import React, { useMemo, useState } from 'react';

import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';

import FooterButton from 'components/FooterButton';
import Table from 'components/Table';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import { organizationRiskAnalysesApiLegacy } from 'config/api/organizationRiskAnalyses/organizationRiskAnalyses';
import { parseOrganizationRiskAnalysesForFE } from 'config/api/organizationRiskAnalyses/organizationRiskAnalyses.parsers';
import { OrganizationRiskAnalyses } from 'config/api/organizationRiskAnalyses/organizationRiskAnalyses.types';
import DATE_FORMAT from 'config/constants/DATE_FORMAT';
import safeT from 'helpers/safeT/safeT';
import useIsOwner from 'hooks/useIsOwner/useIsOwner';
import usePagination from 'hooks/usePagination';
import usePrepareAvailableFilters from 'hooks/usePrepareAvailableFilters/usePrepareAvailableFilters';
import asset_risk_messages from 'messages/asset_risk_messages';
import assets_messages from 'messages/assets_messages';
import general_messages from 'messages/general_messages';
import PATHS from 'router/PATHS';
import assetsCategoryFilter from 'services/_filters/parsers/_assetsFilters/assetsCategoryFilter';
import assetsImportanceFilter from 'services/_filters/parsers/_assetsFilters/assetsImportanceFilter';
import assetsTaskOwnerFilterFactory from 'services/_filters/parsers/_assetTasksFilters/assetsTaskOwnerFilterFactory';
import affectsIndividualsFilter from 'services/_filters/parsers/_riskAnalysisFilters/affectsIndividualsFilter';
import riskAnalysisStatusFilter from 'services/_filters/parsers/_riskAnalysisFilters/riskAnalysisStatus';
import riskQuadrantFilterFactory from 'services/_filters/parsers/_riskAnalysisFilters/riskQuadrantFilterFactory';
import booleanFilterFactory from 'services/_filters/parsers/booleanFilterFactory';
import parseFiltersForRequest from 'services/parseFiltersForRequest';
import { TableDataMappingRow } from 'types/Table';

import RiskQuadrantCell from '../../../SingleRiskAnalysisPage/_components/RiskQuadrantCell/RiskQuadrantCell';

const RiskAnalysisDashboardTable: React.FC = () => {
  const { t } = useTranslation();
  const [filters, setFilters] = useState({});
  const [sort, setSort] = useState<{ key: string | null; direction: string | null }>({ key: null, direction: null });
  const onSort = (key: string, direction: string) => setSort({ key, direction });
  const { checkIsOwner } = useIsOwner();

  const availableFilters = usePrepareAvailableFilters({
    asset__info__assets_importance: assetsImportanceFilter,
    highest_risk_quadrant: riskQuadrantFilterFactory(asset_risk_messages.risk_table.highest_quadrant_risk_filter),
    status: riskAnalysisStatusFilter,
    has_unhandled_risks: booleanFilterFactory(asset_risk_messages.risk_table.has_unhandled_risk_filter),
    asset__info__contains_sensitive_individual_information: affectsIndividualsFilter,
    asset__category: assetsCategoryFilter,
    asset__owner: assetsTaskOwnerFilterFactory('assetRiskAnalyses'),
  });

  const {
    data: tableData,
    getData: getTableData,
    hasNextPage,
    loading,
  } = usePagination({
    endpointFunc: organizationRiskAnalysesApiLegacy.getOrganizationRiskAnalyses,
    limits: [15, 10, 5],
    isGet: true,
    dependencies: [filters],
    sort,
    filters: parseFiltersForRequest(filters, {}, availableFilters || {}),
  });

  const dataMapping: TableDataMappingRow<OrganizationRiskAnalyses>[] = useMemo(
    () => [
      {
        id: 'asset__name',
        label: t(asset_risk_messages.analysis_table.asset_name_column),
        get: ({ asset }) => safeT(t, asset.key, asset.name) || t(general_messages.no_data),
        width: '14%',
      },
      {
        id: 'asset__category__translated_name',
        label: t(asset_risk_messages.analysis_table.asset_type_column),
        get: ({ asset }) => safeT(t, asset.category.nameTranslationKey, asset.category.name) || t(general_messages.no_data),
        width: '12%',
      },
      {
        id: 'asset__owner__full_name',
        label: t(asset_risk_messages.analysis_table.asset_owner_column),
        get: ({ asset }) => <UserAvatar lazy showName userData={asset.owner.profile} userId={asset.owner.id} />,
        width: '20%',
      },
      {
        id: 'date',
        label: t(asset_risk_messages.analysis_table.last_analysis_column),
        get: ({ date }) => format(date, DATE_FORMAT.defaultDate) || t(general_messages.no_data),
        width: '8%',
      },
      {
        id: 'status',
        label: t(asset_risk_messages.analysis_table.status_column),
        get: ({ status }) => t(asset_risk_messages.status[status]),
        width: '8%',
      },
      {
        id: 'asset__info__assets_importance',
        label: t(asset_risk_messages.analysis_table.importance_column),
        get: ({ asset }) =>
          t(assets_messages.information_choices_assets_importance[asset.info.assetsImportance]) || t(general_messages.no_data),
        width: '12%',
      },
      {
        id: 'asset__info__contains_sensitive_individual_information',
        label: t(asset_risk_messages.analysis_table.gdpr_column),
        get: ({ asset }) => (asset.info.containsSensitiveIndividualInformation ? t(general_messages.yes) : t(general_messages.no)),
        width: '10%',
      },
      {
        id: 'highest_risk_quadrant',
        label: t(asset_risk_messages.analysis_table.highest_risk_quadrant_column),
        get: ({ highestRiskQuadrant }) =>
          <RiskQuadrantCell hideDetails riskQuadrant={highestRiskQuadrant} /> || t(general_messages.no_data),
        width: '8%',
      },
      {
        id: 'has_unhandled_risks',
        label: t(asset_risk_messages.analysis_table.unhandled_risk_column),
        get: ({ unhandledRisks }) => (unhandledRisks ? t(general_messages.yes) : t(general_messages.no)),
        width: '8%',
      },
    ],
    [tableData],
  );

  const shouldRowBeDisabled = (row: OrganizationRiskAnalyses) => {
    if (checkIsOwner(row.asset.owner.id)) return false;
    return t(asset_risk_messages.analysis_table.only_owner_can_view_analysis);
  };

  const getRowHref = (row: OrganizationRiskAnalyses) => generatePath(PATHS.RISK_ANALYSIS_DETAIL, { riskAnalysisId: row.id });

  return (
    <>
      <Table
        clickableRow
        data={tableData && parseOrganizationRiskAnalysesForFE(tableData)}
        dataMapping={dataMapping}
        disableClickAction={shouldRowBeDisabled}
        filtersService={availableFilters && { availableFilters, filters, setFilters }}
        getRowHref={getRowHref}
        onSort={onSort}
        openInNewTab
        refetching={loading}
        sort={sort}
      />
      {hasNextPage && (
        // @ts-ignore
        <FooterButton loading={loading} onClick={getTableData}>
          {t(general_messages.show_more)}
        </FooterButton>
      )}
    </>
  );
};

export default RiskAnalysisDashboardTable;

enum RolesOrganization {
  AccountOwner = 0,
  IncidentManager = 1,
  UserAdministrator = 2,
  SecurityOfficer = 3,
  ContractAdministrator = 4,
  ActiveUser = 5,
  AssetsManager = 6,
  EmergencyButton = 7,
  AssetTaskDashboardAccess = 8,
  AssetRiskDashboardAccess = 9,
  AssetRiskTaskDashboardAccess = 10,
  ManagementDashboardAccess = 11,
  AddManagementReportAccess = 12,
}

export const EVERY_ORGANIZATION_ROLE = Object.values(RolesOrganization) as RolesOrganization[];

export default RolesOrganization;

import organizationAssetTasksFilterDataApi, {
  OrganizationAssetTasksFilterEndpoints,
} from 'config/api/organizationAssetTasksFilterData/organizationAssetTasksFilterData';
import { OrganizationAssetTasksFilterData } from 'config/api/organizationAssetTasksFilterData/organizationAssetTasksFilterData.types';
import QUERY_KEYS from 'config/api/QUERY_KEYS';
import assets_messages from 'messages/assets_messages';
import { DynamicTableFilter } from 'types/TableFilter';

const assetsTaskAssetNameFilterFactory = (
  endpoint: OrganizationAssetTasksFilterEndpoints,
): DynamicTableFilter<OrganizationAssetTasksFilterData> => ({
  sectionName: assets_messages.asset_tasks_table.asset_filter_name,
  isDynamic: true,
  queryFn: organizationAssetTasksFilterDataApi.getOrganizationAssetTasksFilterData(endpoint),
  dataParser: ({ name }) => name,
  isSingleSelect: false,
  queryKey: [QUERY_KEYS.GET_ORGANIZATION_ASSET_FILTERS_DATA, endpoint],
});

export default assetsTaskAssetNameFilterFactory;

import React, { FC, useState } from 'react';

import { FormControl, IconButton, InputLabel, MenuItem, Select, Tooltip, Typography, useTheme } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg';
import { ReactComponent as RemoveIcon } from 'assets/icons/trash.svg';
import UploadFileDialog from 'components/_dialogs/UploadFileDialog/UploadFileDialog';
import ColoredButton from 'components/ColoredButton';
import Section from 'components/Section';
import TextButton from 'components/TextButton/TextButton';
import { commonApi } from 'config/api/common';
import managementReportsApi from 'config/api/managementReports/managementReports';
import { RiskStatusInManagementReport, riskStatusInManagementReportChoices } from 'config/api/managementReports/managementReports.types';
import RolesOrganization from 'config/permissions/RolesOrganization';
import downloadFromUrl from 'helpers/downloadFromUrl/downloadFromUrl';
import exhaustiveGuard from 'helpers/exhaustiveGuard/exhaustiveGuard';
import usePermissions from 'hooks/usePermissions';
import general_messages from 'messages/general_messages';
import manage_dashboard_messages from 'messages/manage_dasboard_messages';
import { useConfirmationModalContext } from 'reactContext/ConfirmationModalContext/ConfirmationModalContext';
import { useHelpDialogContext } from 'reactContext/HelpDialogContext/HelpDialogContext';
import Theme from 'styles/theme';
import { FileInForm } from 'types/FileForm';
import { Id } from 'types/Id';

import useGetManagementReportBaseData from '../../_hooks/useGetManagementReportBaseData';
import ManagementCommentInput from '../ManagementCommentInput/ManagementCommentInput';
import ManagementDashboardSectionGrid from '../ManagementDashboardSectionGrid/ManagementDashboardSectionGrid';

import useStyles from './ManagementDashboardSecuritySection.styles';

type Props = {
  reportId?: Id;
};

const resolveStatusColor = (status: RiskStatusInManagementReport, theme: typeof Theme) => {
  switch (status) {
    case 'high':
      return theme.palette.error.main;
    case 'medium':
      // @ts-ignore
      return theme.palette.orange;
    case 'low':
      return theme.palette.secondary.main;
    default:
      exhaustiveGuard(status);
      return '';
  }
};

const ManagementDashboardSecuritySection: FC<Props> = ({ reportId }) => {
  const { t } = useTranslation();
  const { showHelpDialog } = useHelpDialogContext();
  const { enqueueSnackbar } = useSnackbar();
  const [canModifyReports] = usePermissions([RolesOrganization.AddManagementReportAccess]);
  const theme = useTheme();

  // @ts-ignore
  const { showConfirmationModal } = useConfirmationModalContext();
  const showIntroductionDialog = () => {
    showHelpDialog({
      title: t(manage_dashboard_messages.security_section.introduction_title),
      body: t(manage_dashboard_messages.security_section.introduction_body),
    });
  };

  const { data, refetch } = useGetManagementReportBaseData(reportId);

  const removeAttachmentMutation = useMutation(commonApi.removeAttachment);

  const mutation = useMutation(managementReportsApi.partialUpdateManagementReportSecurity(reportId!), {
    onError: () => {
      enqueueSnackbar(t(general_messages.something_went_wrong), { variant: 'error' });
    },
    onSuccess: async () => {
      await refetch();
      enqueueSnackbar(t(general_messages.data_saved), { variant: 'success' });
    },
  });

  const blockUI = mutation.isLoading || removeAttachmentMutation.isLoading;

  const updateSecurity = ({ comment }: { comment: string }) => {
    mutation.mutate({ comment });
  };

  const updateStatus = ({ target }: React.ChangeEvent<{ value: RiskStatusInManagementReport }>) => {
    mutation.mutate({ riskStatus: target.value });
  };

  const uploadFile = async (fileData: FileInForm) => {
    await mutation.mutateAsync({ document: fileData.file });
  };
  const onRemoveFactory = (id: Id) => async () => {
    const confirmed = await showConfirmationModal({
      title: t(general_messages.are_you_sure),
      body: t(general_messages.operation_irreversible),
    });
    if (confirmed) {
      await removeAttachmentMutation.mutateAsync(id);
      await mutation.mutateAsync({ document: null });
    }
  };

  const currentFile = data?.securityReport.document;

  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);
  const closeUploadDialog = () => setIsUploadDialogOpen(false);
  const openUploadDialog = () => setIsUploadDialogOpen(true);

  const onDownloadFactory = (url: string, label: string) => () => downloadFromUrl(url, label);

  const styles = useStyles();

  return (
    <Section
      title={manage_dashboard_messages.security_section.title}
      titleLineAddon={[
        <TextButton key='introduction' onClick={showIntroductionDialog}>
          {t(manage_dashboard_messages.security_section.introduction_button)}
        </TextButton>,
      ]}
    >
      <ManagementDashboardSectionGrid>
        <div className={styles.fileRow}>
          <Tooltip title={currentFile ? t(general_messages.upload_file_blocked_info) : ''}>
            <div>
              {/* @ts-ignore */}
              <ColoredButton customColor='secondary' disabled={!!currentFile || blockUI || !canModifyReports} onClick={openUploadDialog}>
                {t(manage_dashboard_messages.security_section.upload_file_button_label)}
              </ColoredButton>
            </div>
          </Tooltip>
          {currentFile && (
            <div className={styles.fileName}>
              <Typography>{currentFile.name}</Typography>
              <IconButton disabled={blockUI} onClick={onDownloadFactory(currentFile.url, currentFile.name)}>
                <DownloadIcon />
              </IconButton>
              <IconButton disabled={blockUI} onClick={onRemoveFactory(currentFile.id)}>
                <RemoveIcon />
              </IconButton>
            </div>
          )}
        </div>
        <FormControl className={styles.selectInput} variant='outlined'>
          <InputLabel>{t(manage_dashboard_messages.security_section.risk_status_select_label)}</InputLabel>
          <Select
            disabled={!canModifyReports}
            label={t(manage_dashboard_messages.security_section.risk_status_select_label)}
            // @ts-ignore
            onChange={updateStatus}
            style={{ color: resolveStatusColor(data?.securityReport.riskStatus || 'medium', theme) }}
            value={data?.securityReport.riskStatus || ''}
            variant='outlined'
          >
            {riskStatusInManagementReportChoices.map(status => (
              <MenuItem key={status} style={{ color: resolveStatusColor(status, theme) }} value={status}>
                {t(manage_dashboard_messages.risk_status[status])}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <ManagementCommentInput
          customMutation={updateSecurity}
          fieldKey=''
          initialValue={data?.securityReport.comment || ''}
          reportId={reportId}
        />
      </ManagementDashboardSectionGrid>
      <UploadFileDialog onClose={closeUploadDialog} onSubmit={uploadFile} open={isUploadDialogOpen} withLabelField={false} />
    </Section>
  );
};

export default ManagementDashboardSecuritySection;

import { format } from 'date-fns';
import { isNil, set } from 'lodash';

import DATE_FORMAT from '../../constants/DATE_FORMAT';
import { parseUserForFE } from '../_parsers/parseUser';

import type {
  ManagementReport,
  ManagementReportDetail,
  ManagementReportDetailDTO,
  ManagementReportDTO,
  ManagementReportPartialUpdateDTO,
  ManagementReportPartialUpdateForm,
  ManagementReportSecurityPartialUpdateDTO,
  ManagementReportSecurityPartialUpdateForm,
} from './managementReports.types';

export const parseAllManagementReportsForFE = (dtos: ManagementReportDTO[]): ManagementReport[] =>
  dtos.map(dto => ({
    id: dto.id,
    createdAt: new Date(dto.created_at),
    updatedAt: new Date(dto.updated_at),
    createdBy: parseUserForFE(dto.created_by),
    dateFrom: new Date(dto.date_from),
    dateTo: new Date(dto.date_to),
  }));

export const parseSingleManagementReportsForFE = (dto: ManagementReportDetailDTO): ManagementReportDetail => ({
  id: dto.id,
  createdAt: new Date(dto.created_at),
  updatedAt: new Date(dto.updated_at),
  createdBy: parseUserForFE(dto.created_by),
  dateFrom: new Date(dto.date_from),
  dateTo: new Date(dto.date_to),
  securityReport: {
    id: dto.security_report.id,
    document: dto.security_report.document
      ? {
          id: dto.security_report.document.id,
          name: dto.security_report.document.name,
          url: dto.security_report.document.url,
        }
      : null,
    comment: dto.security_report.comment || '',
    riskStatus: dto.security_report.risk_status,
  },
  majorIncidentsReport: {
    id: dto.major_incidents_report.id,
    comment: dto.major_incidents_report.comment || '',
    totalIncidents: dto.major_incidents_report.total_incidents,
    gdprIncidents: dto.major_incidents_report.gdpr_incidents,
    lowIncidents: dto.major_incidents_report.low_incidents,
    mediumIncidents: dto.major_incidents_report.medium_incidents,
    highIncidents: dto.major_incidents_report.high_incidents,
    highestIncidents: dto.major_incidents_report.highest_incidents,
    ongoingIncidents: dto.major_incidents_report.ongoing_incidents,
  },
  risksReport: {
    id: dto.risks_report.id,
    comment: dto.risks_report.comment || '',
    assetsAnalyzedLastYear: dto.risks_report.assets_analyzed_last_year,
    assetsNotAnalyzedLastYear: dto.risks_report.assets_not_analyzed_last_year,
    assetsWithQ1Risk: dto.risks_report.assets_with_q1_risk,
    assetsWithQ2Risk: dto.risks_report.assets_with_q2_risk,
    assetsWithQ1RiskNotToHandle: dto.risks_report.assets_with_q1_risk_not_to_handle,
    assetsWithQ2RiskNotToHandle: dto.risks_report.assets_with_q2_risk_not_to_handle,
  },
  assetTasksReport: dto.asset_tasks_report
    ? {
        id: dto.asset_tasks_report.id,
        comment: dto.asset_tasks_report.comment || '',
      }
    : null,
  riskTasksReport: {
    id: dto.risk_tasks_report.id,
    comment: dto.risk_tasks_report.comment || '',
    notStartedTasks: dto.risk_tasks_report.not_started_tasks,
    startedTasks: dto.risk_tasks_report.started_tasks,
    overdueTasks: dto.risk_tasks_report.overdue_tasks,
  },
});

export const parseManagementReportPartialUpdate = (
  data: Partial<ManagementReportPartialUpdateForm>,
): Partial<ManagementReportPartialUpdateDTO> => {
  const result: Partial<ManagementReportPartialUpdateDTO> = {};
  if (data.dateTo) result.date_to = format(data.dateTo, DATE_FORMAT.defaultDate);
  if (data.dateFrom) result.date_from = format(data.dateFrom, DATE_FORMAT.defaultDate);
  if (!isNil(data.majorIncidentsReport?.comment)) set(result, 'major_incidents_report.comment', data.majorIncidentsReport!.comment);
  if (!isNil(data.risksReport?.comment)) set(result, 'risks_report.comment', data.risksReport!.comment);
  if (!isNil(data.riskTasksReport?.comment)) set(result, 'risk_tasks_report.comment', data.riskTasksReport!.comment);
  if (!isNil(data.assetTasksReport?.comment)) set(result, 'asset_tasks_report.comment', data.assetTasksReport!.comment);
  return result;
};

export const parseManagementReportSecurityPartialUpdate = (
  data: Partial<ManagementReportSecurityPartialUpdateForm>,
): Partial<ManagementReportSecurityPartialUpdateDTO> => {
  const result: Partial<ManagementReportSecurityPartialUpdateDTO> = {};
  if (!isNil(data.comment)) result.comment = data.comment;
  if (!isNil(data.riskStatus)) result.risk_status = data.riskStatus;
  if (!isNil(data.document)) result.document = data.document;
  return result;
};

import axios from 'axios';

import { parseOrganizationAssetTasksFilterDataToTableFilters } from './organizationAssetTasksFilterData.parsers';
import type OrganizationAssetTasksFilterDataApi from './organizationAssetTasksFilterData.types';

export const endpointsDictionary = {
  assetTasks: 'api/v1/organization/asset_tasks/filter_data/',
  assetRiskAnalyses: 'api/v1/organization/risk_analyses/filter_data/',
  assetRiskTasks: 'api/v1/organization/asset_risk_tasks/filter_data/',
} as const;

export type OrganizationAssetTasksFilterEndpoints = keyof typeof endpointsDictionary;

const organizationAssetTasksFilterDataApi: OrganizationAssetTasksFilterDataApi = {
  getOrganizationAssetTasksFilterData: endpoint => async () => {
    const { data } = await axios.get(endpointsDictionary[endpoint]);
    return parseOrganizationAssetTasksFilterDataToTableFilters(data);
  },
};

export default organizationAssetTasksFilterDataApi;

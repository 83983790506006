import React, { FC } from 'react';

import useStyles from './ManagementDashboardSectionGrid.styles';

type Props = {
  children: React.ReactNode | React.ReactNode[];
};

const ManagementDashboardSectionGrid: FC<Props> = ({ children }) => {
  const styles = useStyles();

  return <div className={styles.container}>{children}</div>;
};

export default ManagementDashboardSectionGrid;

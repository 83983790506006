import { omitBy } from 'lodash';

import { CreateAssetFormInput } from 'components/_dialogs/AddAssetDialog/AddAssetDialog';
import { EditAssetFormInput } from 'components/AssetEditForm/AssetEditForm';
import { Id } from 'types/Id';

import { parseUserForFE } from '../_parsers/parseUser';
import { parseSingleAssetContactsForFE } from '../assetContacts/assetContacts.parsers';
import { AssetContactCategory, AssetContactCategoryDTO } from '../assetContacts/assetContacts.types';
import { parseSingleAssetDocumentForFE } from '../assetDocuments/assetDocuments.parsers';

import type { Asset, AssetDetails, AssetDetailsDTO, AssetDTO } from './assets.types';
import { AssetInfo, AssetInfoDTO, AssetInputDTO } from './assets.types';

const parseInfoForBE = (infoFromFE: AssetInfo): Partial<AssetInfoDTO> => {
  const result = {
    asset_description: infoFromFE.assetDescription,
    confidentiality: infoFromFE.confidentiality,
    internal_asset_identity: infoFromFE.internalAssetIdentity,
    asset_sub_category: infoFromFE.assetSubcategory,
    integrity_change_control: infoFromFE.integrityChangeControl,
    assets_importance: infoFromFE.assetsImportance,
    availability: infoFromFE.availability,
    placement_of_asset: infoFromFE.placementOfAsset,
    current_status_of_asset: infoFromFE.currentStatusOfAsset,
    economical_value_of_asset: infoFromFE.economicalValueOfAsset,
    identifiable_information: infoFromFE.identifiableInformation,
    sensitive_individual_information: infoFromFE.sensitiveIndividualInformation,
    legal_demands: infoFromFE.legalDemands,
  };
  return omitBy(result, value => !value);
};

export const parseAssetInListForFE = (rawAsset: AssetDTO): Asset => ({
  info: {
    assetDescription: rawAsset.info.asset_description,
    confidentiality: rawAsset.info.confidentiality,
    assetsImportance: rawAsset.info.assets_importance,
  },
  category: {
    id: rawAsset.category.id,
    name: rawAsset.category.name,
    nameTranslationKey: rawAsset.category.name_translation_key,
  },
  owner: parseUserForFE(rawAsset.owner),
  name: rawAsset.name,
  key: rawAsset.key,
  id: rawAsset.id,
});

export const parseAllAssetForFE = (raw: (AssetDTO & { owner: { id: Id; first_name: string; last_name: string } })[]): Asset[] =>
  raw.map(parseAssetInListForFE);

export const parseSingleAssetForFE = (raw: AssetDetailsDTO): AssetDetails => ({
  info: {
    assetDescription: raw.info.asset_description,
    confidentiality: raw.info.confidentiality,
    internalAssetIdentity: raw.info.internal_asset_identity,
    assetSubcategory: raw.info.asset_sub_category,
    integrityChangeControl: raw.info.integrity_change_control,
    assetsImportance: raw.info.assets_importance,
    availability: raw.info.availability,
    placementOfAsset: raw.info.placement_of_asset,
    currentStatusOfAsset: raw.info.current_status_of_asset,
    economicalValueOfAsset: raw.info.economical_value_of_asset,
    identifiableInformation: raw.info.identifiable_information,
    sensitiveIndividualInformation: raw.info.sensitive_individual_information,
    legalDemands: raw.info.legal_demands,
  },
  category: {
    id: raw.category.id,
    name: raw.category.name,
    nameTranslationKey: raw.category.name_translation_key,
  },
  owner: parseUserForFE(raw.owner),
  name: raw.name,
  key: raw.key,
  id: raw.id,
  documents: raw.documents.map(parseSingleAssetDocumentForFE),
  contacts: raw.contacts.map(parseSingleAssetContactsForFE),
});

export const parseCreateInputForBE = (formData: CreateAssetFormInput): Omit<AssetInputDTO, 'info'> => ({
  name: formData.name,
  category_id: formData.category as unknown as number,
  owner_id: formData.owner[0].key,
});

export const parseUpdateInputForBE = (formData: EditAssetFormInput): Omit<AssetInputDTO, 'info'> & { info: Partial<AssetInfoDTO> } => ({
  name: formData.name,
  owner_id: formData.owner[0].key,
  category_id: formData.categoryId,
  info: parseInfoForBE(formData.info),
});

export const parseAssetCategoryContactForFE = (raw: AssetContactCategoryDTO): AssetContactCategory => ({
  contactCategories: raw.contact_categories,
});

import React, { useMemo, useState } from 'react';

import { Container, Typography, useMediaQuery } from '@material-ui/core';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { generatePath } from 'react-router-dom';

import CarouselWrapper from 'components/CarouselWrapper/CarouselWrapper';
import ColoredTile from 'components/ColoredTile';
import FooterButton from 'components/FooterButton';
import Jumbotron from 'components/Jumbotron';
import Loader from 'components/Loader';
import MobileGuttersContainer from 'components/MobileGuttersContainer';
import Section from 'components/Section';
import Table from 'components/Table';
import TrimHTMLText from 'components/TrimHTMLText/TrimHTMLText';
import TypographyWithHTML from 'components/TypographyWithHTML/TypographyWithHTML';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import assetRiskTasksApi, { legacyAssetRiskTasksApi } from 'config/api/assetRiskTasks/assetRiskTasks';
import { parseAllAssetRiskTasksForFE } from 'config/api/assetRiskTasks/assetRiskTasks.parsers';
import { AssetRiskTasks, AssetRiskTasksStats } from 'config/api/assetRiskTasks/assetRiskTasks.types';
import QUERY_KEYS from 'config/api/QUERY_KEYS';
import DATE_FORMAT from 'config/constants/DATE_FORMAT';
import pubsub from 'config/events/pubsub';
import useIsOwner from 'hooks/useIsOwner/useIsOwner';
import usePagination from 'hooks/usePagination';
import usePrepareAvailableFilters from 'hooks/usePrepareAvailableFilters/usePrepareAvailableFilters';
import useSubscription from 'hooks/useSubscription';
import asset_risk_messages from 'messages/asset_risk_messages';
import asset_risk_tasks_messages from 'messages/asset_risk_tasks_messages';
import assets_messages from 'messages/assets_messages';
import general_messages from 'messages/general_messages';
import PATHS from 'router/PATHS';
import assetsImportanceFilter from 'services/_filters/parsers/_assetsFilters/assetsImportanceFilter';
import assetsTaskAssetNameFilterFactory from 'services/_filters/parsers/_assetTasksFilters/assetsTaskAssetNameFilterFactory';
import assetsTaskOwnerFilterFactory from 'services/_filters/parsers/_assetTasksFilters/assetsTaskOwnerFilterFactory';
import assetTaskStatusFilter from 'services/_filters/parsers/_assetTasksFilters/assetTaskStatusFilter';
import isOverdueTaskFilter from 'services/_filters/parsers/_assetTasksFilters/isOverdueTaskFilter';
import parseFiltersForRequest from 'services/parseFiltersForRequest';
import { TableDataMappingRow } from 'types/Table';

const AssetRiskTaskDashboardPage: React.FC = () => {
  const { t } = useTranslation();
  // @ts-ignore
  const upMd = useMediaQuery(theme => theme.breakpoints.up('md'));
  const { checkIsOwner } = useIsOwner();

  const { data: kpiData } = useQuery(QUERY_KEYS.GET_ORGANIZATION_RISK_TASKS_KPI, assetRiskTasksApi.getAssetRiskTasksStats);

  const availableFilters = usePrepareAvailableFilters({
    risk__risk_analysis__asset__info__assets_importance: assetsImportanceFilter,
    status: assetTaskStatusFilter,
    is_overdue: isOverdueTaskFilter,
    risk__risk_analysis__asset__owner: assetsTaskOwnerFilterFactory('assetRiskTasks'),
    risk__risk_analysis__asset__name: assetsTaskAssetNameFilterFactory('assetRiskTasks'),
  });

  const [sort, setSort] = useState<{ key: string | null; direction: string | null }>({ key: null, direction: null });
  const onSort = (key: string, direction: string) => setSort({ key, direction });
  const [filters, setFilters] = useState({});

  const {
    data: tableData,
    getData: getTableData,
    hasNextPage,
    loading,
    initialLoading,
    refreshData,
  } = usePagination({
    endpointFunc: legacyAssetRiskTasksApi.getAllAssetRiskTasks('organization'),
    limits: [15, 10, 5],
    isGet: true,
    dependencies: [filters],
    sort,
    filters: parseFiltersForRequest(filters, {}, availableFilters || {}),
  });
  useSubscription(pubsub.RISK_ANALYSIS_TASK_UPDATED, refreshData);

  const dataMapping: TableDataMappingRow<AssetRiskTasks>[] = useMemo(
    () => [
      {
        label: t(asset_risk_tasks_messages.table.asset_name_column),
        id: 'risk__risk_analysis__asset__name',
        get: ({ risk }) => risk.riskAnalysis.asset.name,
        width: '12%',
      },
      {
        label: t(asset_risk_tasks_messages.table.assigned_column),
        id: 'assigned_to__full_name',
        get: ({ assignedTo }) => <UserAvatar showName userData={assignedTo.profile} userId={assignedTo.id} />,
        width: '14%',
      },
      {
        label: t(asset_risk_tasks_messages.table.asset_importance_column),
        id: 'risk__risk_analysis__asset__info__assets_importance',
        get: ({ risk }) => t(assets_messages.information_choices_assets_importance[risk.riskAnalysis.asset.info.assetsImportance]),
        width: '12%',
      },
      {
        label: t(asset_risk_tasks_messages.table.analysis_date_column),
        id: 'risk__risk_analysis__date',
        get: ({ risk }) => format(risk.riskAnalysis.date, DATE_FORMAT.defaultDate),
        width: '11%',
      },
      {
        label: t(asset_risk_tasks_messages.table.risk_name_column),
        id: 'risk__name',
        get: ({ risk }) => risk.name,
        width: '15%',
      },
      {
        id: 'description',
        label: t(asset_risk_tasks_messages.table.description_column),
        get: ({ description }) => (
          <TrimHTMLText linesToShow={2}>
            <TypographyWithHTML>{description}</TypographyWithHTML>
          </TrimHTMLText>
        ),
        width: '18%',
      },
      {
        label: t(asset_risk_tasks_messages.table.due_date_column),
        id: 'due_date',
        get: ({ dueDate }) => format(dueDate, DATE_FORMAT.defaultDate),
        width: '9%',
      },
      {
        label: t(asset_risk_tasks_messages.table.status_column),
        id: 'status',
        get: ({ status }) => t(asset_risk_messages.risk_task_status[status]),
        width: '9%',
      },
    ],
    [],
  );

  const shouldRowBeDisabled = (row: AssetRiskTasks) => {
    if (checkIsOwner(row.risk.riskAnalysis.asset.owner.id)) return false;
    return t(asset_risk_messages.analysis_table.only_owner_can_view_analysis);
  };

  const getRowHref = ({ risk }: AssetRiskTasks) => generatePath(PATHS.RISK_ANALYSIS_DETAIL, { riskAnalysisId: risk.riskAnalysis.id });

  return (
    <Container disableGutters={!upMd}>
      <Jumbotron>
        <Typography component='h1' variant='h1'>
          {t(asset_risk_tasks_messages.dashboard.title)}
        </Typography>
      </Jumbotron>
      <Section gutters narrow>
        <TypographyWithHTML>{t(asset_risk_tasks_messages.dashboard.description)}</TypographyWithHTML>
      </Section>
      <CarouselWrapper noTopPadding>
        {kpiData
          ? Object.entries(kpiData).map(([key, value]) => (
              <ColoredTile
                key={key}
                centered
                description={t(asset_risk_tasks_messages.stats[key as keyof AssetRiskTasksStats])}
                disableRipple
                title={value}
              />
            ))
          : // eslint-disable-next-line prefer-spread
            Array.apply(null, Array(4)).map((_, index) => <ColoredTile key={index} centered isLoading />)}
      </CarouselWrapper>
      <MobileGuttersContainer>
        {initialLoading ? (
          <Loader inner />
        ) : (
          <Table
            clickableRow
            data={tableData && parseAllAssetRiskTasksForFE(tableData)}
            dataMapping={dataMapping}
            disableClickAction={shouldRowBeDisabled}
            filtersService={{ availableFilters, filters, setFilters }}
            fixedHeader
            getRowHref={getRowHref}
            onSort={onSort}
            openInNewTab
            refetching={loading && !initialLoading}
            sort={sort}
          />
        )}
        {hasNextPage && (
          // @ts-ignore
          <FooterButton loading={loading} onClick={getTableData}>
            {t(general_messages.show_more)}
          </FooterButton>
        )}
      </MobileGuttersContainer>
    </Container>
  );
};

export default AssetRiskTaskDashboardPage;

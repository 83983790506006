// TODO I6.5 to remove after merge with I6

const ROLES = {
  PS_ROLES: {
    CONTENT_EDITOR: 'pocketsafe_content_editor',
    CUSTOMER_ADMINISTRATOR: 'pocketsafe_customer_administrator',
    SECURITY_ONLINE: 'pocketsafe_security_online',
    SECURITY_SUPERVISOR: 'pocketsafe_security_supervisor',
  },
  ORGANIZATION: {
    ACCOUNT_OWNER: 0,
    INCIDENT_MANAGER: 1,
    USER_ADMINISTRATOR: 2,
    SECURITY_OFFICER: 3,
    CONTRACT_ADMINISTRATOR: 4,
    ACTIVE_USER: 5,
    ASSETS_MANAGER: 6,
    EMERGENCY_BUTTON: 7,
    ASSET_TASK_DASHBOARD_ACCESS: 8,
    ASSET_RISK_DASHBOARD_ACCESS: 9,
    ASSET_RISK_TASK_DASHBOARD_ACCESS: 10,
    MANAGEMENT_DASHBOARD_ACCESS: 11,
    ADD_MANAGEMENT_DASHBOARD_ACCESS: 12,
  },
};

export default ROLES;
